import { createSlice } from "@reduxjs/toolkit";
import { getAnnouncementAction, insertUpdateAnnouncementAction } from "../../actions/webContentsSettingsActions/announcementAction";
import { toast } from 'react-toastify';

const INITIAL_STATE = {

    selectedAnnouncement: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    insertUpdateAnnouncement: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },



};

const AnnouncementReducer = createSlice({
    name: "Announcement",
    initialState: INITIAL_STATE,
    reducers: {

        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetAnnouncementDetailsState(state, action) {
            state.selectedAnnouncement = INITIAL_STATE.selectedAnnouncement;
            state.insertUpdateAnnouncement = INITIAL_STATE.insertUpdateAnnouncement;
        },
    },
    extraReducers: (builder) => {

        builder.addCase(
            insertUpdateAnnouncementAction.fulfilled,
            (state, action) => {

                state.insertUpdateAnnouncement.data = action.payload.data.data;
                state.insertUpdateAnnouncement.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateAnnouncement.apiMsg.message = action.payload.message.message;


                toast.success(action.payload.message.message, {
                    position: "bottom-center",
                    toastId: "dept-error"
                });
            }
        );
        builder.addCase(
            insertUpdateAnnouncementAction.pending,
            (state, action) => {
                state.insertUpdateAnnouncement.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateAnnouncementAction.rejected,
            (state, action) => {

                state.insertUpdateAnnouncement.apiMsg.message = action.error.message;
                state.insertUpdateAnnouncement.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: "bottom-center",
                    toastId: "dept-error"
                });
            }
        );


        builder.addCase(getAnnouncementAction.fulfilled, (state, action) => {
            state.selectedAnnouncement.data = action.payload.data;
            state.selectedAnnouncement.apiMsg.status = action.meta.requestStatus;
            state.selectedAnnouncement.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getAnnouncementAction.pending, (state, action) => {
            state.selectedAnnouncement.data = null;
            state.selectedAnnouncement.apiMsg.message = action.meta.requestStatus;;
            state.selectedAnnouncement.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getAnnouncementAction.rejected, (state, action) => {
            state.selectedAnnouncement.apiMsg.message = action.error.message;
            state.selectedAnnouncement.apiMsg.status = action.meta.requestStatus;
        });

    }
});

export const { RESET, ResetAnnouncementDetailsState } = AnnouncementReducer.actions;
export default AnnouncementReducer.reducer;