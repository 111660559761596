import axios from 'axios';
import { getLocalStore, clearLocalStore } from '../localStorage/index';
import { getCookie,removeCookie } from '../cookieStorage';

var headers = {
    "Accept": "application/json",
    "Content-Type": "application/json",
}

// Add a 401 response interceptor
axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (401 === error.response.status) {
        logoutUser();
        window.location = '/login';
    } else {
        return Promise.reject(error);
    }
});

export const apiCall = async (path, method, data, sizesCheck) => {

    if (getCookie(process.env.REACT_APP_TOKEN_AUTHENTICATION_KEY) && getLocalStore(process.env.REACT_APP_LOCAL_STORAGE_KEY_USER)) {
        axios.defaults.headers = {
            Accept: "application/json",
            Authorization: `Bearer ${getCookie(process.env.REACT_APP_TOKEN_AUTHENTICATION_KEY)}`,
            businessId: getLocalStore(process.env.REACT_APP_LOCAL_STORAGE_KEY_USER).businessId
        };
    }
    
    if (method === 'POST') {
        headers["Content-Type"] = 'application/json';
        var res = await axios({
            method: method,
            url: sizesCheck ? path : `${process.env.REACT_APP_BACKEND_URL_API}${path}`,
            headers,
            data: data
        }).then(response => {
            return response;
        }).catch(error => {
            console.log("API Error", error)
            return error.response;
        })
    }
    else {

        var res = await axios({
            method: method,
            url: sizesCheck ? path : `${process.env.REACT_APP_BACKEND_URL_API}${path}`,
            headers,

        }).then(response => {
            return response;

        }).catch(error => {
            console.log("API Error", error)
            return error.response;
        })
    }
    return res;
}

export const apiFileUploadCall = async (path, method, data, sizesCheck) => {
    if (method === 'POST') {
        headers["Content-Type"] = 'multipart/form-data';
        var res = await axios({
            method: method,
            url: sizesCheck ? path : `${process.env.REACT_APP_BACKEND_URL_API}${path}`,
            headers,
            data: data
        }).then(response => {
            return response;

        }).catch(error => {
            console.log("API Error", error)
            return error.response;
        })
    }
    else {

        var res = await axios({
            method: method,
            url: sizesCheck ? path : `${process.env.REACT_APP_BACKEND_URL_API}${path}`,
            headers,

        }).then(response => {
            return response;

        }).catch(error => {
            console.log("API Error", error)
            return error.response;
        })
    }

    return res;
}

export const apiUploadCall = async (path, files, setPercentage) => {

    var formData = new FormData();

    formData.append('file', { uri: files.filePath, name: files.fileName, type: files.contentType });
    headers["Content-Type"] = 'multipart/form-data';



    var res = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_BACKEND_URL_API}${path}`,
        headers,
        data: files,

    }).then(response => {
        return {
            ...response.data,
            file_path: `${response.data.storage}${response.data.file_path}`,

        };

    }).catch(error => {
        console.log("API Error", error)
        return error.response;
    })
    return res;
}

export const logoutUser = async () => {
    axios.defaults.headers = {
    };
    clearLocalStore(true);
    removeCookie(process.env.REACT_APP_TOKEN_AUTHENTICATION_KEY);
    return true;
}