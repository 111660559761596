import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { Enums } from "../../../../helper/constants/enums";
import { getAllProductsAction, insertUpdateProductAction, changeStatusProductAction, getProductAction } from "../../../actions/productsActions/productAction/productActions";
const INITIAL_STATE = {
    Products: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdateProductDetail: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    selectedProduct: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    changeStatusProductDetail: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    }
};

const ProductReducer = createSlice({
    name: "Product",
    initialState: INITIAL_STATE,
    reducers: {

        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetProductDetailsState(state, action) {
            state.selectedProduct = INITIAL_STATE.selectedProduct;
            state.insertUpdateProductDetail = INITIAL_STATE.insertUpdateProductDetail;
        },
    },

    extraReducers: (builder) => {

        builder.addCase(getAllProductsAction.fulfilled, (state, action) => {
            state.Products.data = action.payload.data;
            state.Products.apiMsg.status = action.meta.requestStatus;
            state.Products.apiMsg.message = "success";
        });
        builder.addCase(getAllProductsAction.pending, (state, action) => {
            state.Products.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllProductsAction.rejected, (state, action) => {
            state.Products.apiMsg.message = action.error.message;
            state.Products.apiMsg.status = action.meta.requestStatus;
        });



        builder.addCase(getProductAction.fulfilled, (state, action) => {
            state.selectedProduct.data = action.payload.data;
            state.selectedProduct.apiMsg.status = action.meta.requestStatus;
            state.selectedProduct.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getProductAction.pending, (state, action) => {
            state.selectedProduct.data = null;
            state.selectedProduct.apiMsg.message = action.meta.requestStatus;;
            state.selectedProduct.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getProductAction.rejected, (state, action) => {
            state.selectedProduct.apiMsg.message = action.error.message;
            state.selectedProduct.apiMsg.status = action.meta.requestStatus;
        });


        builder.addCase(
            insertUpdateProductAction.fulfilled,
            (state, action) => {

                state.insertUpdateProductDetail.data = action.payload.data.data;
                state.insertUpdateProductDetail.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateProductDetail.apiMsg.message = action.payload.message.message;

                if (action.meta.arg) {
                    const newData = action.meta.arg;
                    const existingRecords = state.Products.data.records;

                    const index = existingRecords.findIndex(record => record.productID === newData.productID);
                    if (!newData.productID) {
                        newData.productID = action.payload.data.data;
                    }
                    if (index !== -1) {
                        existingRecords[index] = {
                            ...existingRecords[index],
                            ...newData
                        };

                    }
                    else {

                        existingRecords.push({
                            ...newData
                        });

                        state.Products.data.totalRecords = state.Products.data.totalRecords + 1;

                    }

                    state.Products.data.records = existingRecords;


                    state.insertUpdateProductDetail.data = {
                        ...newData
                    };
                }

                toast.success(action.payload.message.message, {
                    position: "bottom-center",
                    toastId: "success"
                });
            }
        );
        builder.addCase(
            insertUpdateProductAction.pending,
            (state, action) => {
                state.insertUpdateProductDetail.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateProductAction.rejected,
            (state, action) => {

                state.insertUpdateProductDetail.apiMsg.message = action.error.message;
                state.insertUpdateProductDetail.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: "bottom-center",
                    toastId: "error"
                });
            }
        );




        builder.addCase(changeStatusProductAction.fulfilled, (state, action) => {
            if (action.meta.arg.StatusType === Enums.ChangeStatus.Delete) {
                const existingRecords = state.Products.data.records;
                const roleIdToDelete = action.payload.data.Id;
                const updatedRecords = existingRecords.filter(record => record.productID !== roleIdToDelete);
                state.Products.data.records = updatedRecords;
                state.Products.data.totalRecords = state.Products.data.totalRecords - 1;

            }
            else if ((action.meta.arg.StatusType === Enums.ChangeStatus.Active) || (action.meta.arg.StatusType === Enums.ChangeStatus.Inactive)) {
                const newData = action.meta.arg;
                const existingRecords = state.Products.data.records;

                const index = existingRecords.findIndex(record => record.productID === newData.Id);
                if (index !== -1) {
                    existingRecords[index] = {
                        ...existingRecords[index],
                        active: action.meta.arg.StatusType === Enums.ChangeStatus.Active ? true : false,
                    };
                }
                state.Products.data.records = existingRecords;
            }
            else if ((action.meta.arg.StatusType === Enums.ChangeStatus.DisplayHomePageOff) || (action.meta.arg.StatusType === Enums.ChangeStatus.DisplayHomePageOn)) {
                const newData = action.meta.arg;
                const existingRecords = state.Products.data.records;

                const index = existingRecords.findIndex(record => record.productID === newData.Id);
                if (index !== -1) {
                    existingRecords[index] = {
                        ...existingRecords[index],
                        displayHomePage: action.meta.arg.StatusType === Enums.ChangeStatus.DisplayHomePageOn ? true : false,
                    };
                }
                state.Products.data.records = existingRecords;
            }

            state.changeStatusProductDetail.apiMsg.status = action.meta.requestStatus;
            state.changeStatusProductDetail.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(changeStatusProductAction.pending, (state, action) => {
            state.changeStatusProductDetail.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(changeStatusProductAction.rejected, (state, action) => {
            state.changeStatusProductDetail.apiMsg.message = action.error.message;
            state.changeStatusProductDetail.apiMsg.status = action.meta.requestStatus;
        });
    }
})

export const { RESET, ResetProductDetailsState } = ProductReducer.actions;
export default ProductReducer.reducer;