import { Cookies } from 'react-cookie';

export const setCookie = (KEY, VAlUE,EXP) => {
    const cookies = new Cookies();
    if(EXP){
        cookies.set(KEY, VAlUE, { path: '/', maxAge: 86400 });
    }
    else{
        cookies.set(KEY, VAlUE, { path: '/', maxAge: 86400 });
    }
    
}
export const getCookie = (KEY) => {
    const cookies = new Cookies();
   return cookies.get(KEY);
}
export const setCookieObject = (KEY, VAlUE) => {
    const cookies = new Cookies();
    cookies.set(KEY, JSON.stringify(VAlUE), { path: '/', maxAge: 86400 })
}
export const getCookieObject = (KEY) => {
    const cookies = new Cookies();
    return JSON.parse(cookies.get(KEY));
}
export const removeCookie = (KEY) => {
    const cookies = new Cookies();
    cookies.remove(KEY, { path: '/'});
}



