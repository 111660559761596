import { createSlice } from "@reduxjs/toolkit";
import { getAllDepartmentsAction, getDepartmentsAction, insertUpdateDepartmentsAction, changeStatusDepartmentsAction } from "../../../actions/productsActions/departmentsActions/departmentsActions";
import { toast } from 'react-toastify';
import { Enums } from "../../../../helper/constants/enums";

const INITIAL_STATE = {
    allDepartments: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedDepartment: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdateDepartment: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    changeStatusDepartment: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    }
};

const DepartmentReducer = createSlice({
    name: "Department",
    initialState: INITIAL_STATE,
    reducers: {

        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetDepartmentDetailsState(state, action) {
            state.selectedDepartment = INITIAL_STATE.selectedDepartment;
            state.insertUpdateDepartment = INITIAL_STATE.insertUpdateDepartment;
        },
    },
    extraReducers: (builder) => {

        builder.addCase(getAllDepartmentsAction.fulfilled, (state, action) => {
            // console.log("action.payload.data", action.payload);
            state.allDepartments.data = action.payload.data;
            state.allDepartments.apiMsg.status = action.meta.requestStatus;
            state.allDepartments.apiMsg.message = "success";
            // console.log( "state.allDepartments.data",  state.allDepartments.data.records);
        });
        builder.addCase(getAllDepartmentsAction.pending, (state, action) => {
            state.allDepartments.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllDepartmentsAction.rejected, (state, action) => {
            state.allDepartments.apiMsg.message = action.error.message;
            state.allDepartments.apiMsg.status = action.meta.requestStatus;
        });


        builder.addCase(getDepartmentsAction.fulfilled, (state, action) => {
            state.selectedDepartment.data = action.payload.data;
            state.selectedDepartment.apiMsg.status = action.meta.requestStatus;
            state.selectedDepartment.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getDepartmentsAction.pending, (state, action) => {
            state.selectedDepartment.data = null;
            state.selectedDepartment.apiMsg.message = action.meta.requestStatus;;
            state.selectedDepartment.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getDepartmentsAction.rejected, (state, action) => {
            state.selectedDepartment.apiMsg.message = action.error.message;
            state.selectedDepartment.apiMsg.status = action.meta.requestStatus;
        });


        builder.addCase(
            insertUpdateDepartmentsAction.fulfilled,
            (state, action) => {

                if (action.meta.arg) {
                    const newData = action.meta.arg;
                    const existingRecords = state.allDepartments.data.records;
                    const index = existingRecords ? existingRecords.findIndex(record => record.categoryID === newData.categoryID) : -1;

                    if (!newData.categoryID) {
                        newData.categoryID = action.payload.data.data;
                    }

                    if (index !== -1) {
                        existingRecords[index] = {
                            ...existingRecords[index],
                            ...newData
                            // name: newData.name,
                            // description: newData.description,
                            // image: newData.image,
                            // sortOrder: newData.sortOrder,
                            // disableInApp: newData.disableInApp,
                            // h1Tag: newData.h1Tag,
                            // metaTitle: newData.metaTitle,
                            // metaDescription: newData.metaDescription,
                            // active: newData.active,
                            // productCount: newData.productCount
                            // productCount: newData.
                        };
                    }
                    else {
                        // if (state.allDepartments.data.records.length) {
                        //     state.allDepartments.data.records.unshift({
                        //         CategoryID: action.payload.data.data,
                        //         name: newData.Name,
                        //         description: newData.Description,
                        //         image: newData.Image,
                        //         sortOrder: newData.SortOrder,
                        //         disableInApp: newData.DisaleInApp,
                        //         h1Tag: newData.H1Tag,
                        //         metaTitle: newData.MetaTitle,
                        //         metaDescription: newData.MetaDescription,
                        //         active: newData.Active
                        //     });
                        // }
                        // else {
                        existingRecords.push(newData);
                        state.allDepartments.data.totalRecords = state.allDepartments.data.totalRecords + 1;
                    }
                    state.allDepartments.data.records = existingRecords;

                    state.insertUpdateDepartment.data = {
                        categoryID: newData.categoryID,
                        name: newData.name,
                        description: newData.description,
                        image: newData.image,
                        sortOrder: newData.sortOrder,
                        disableInApp: newData.disableInApp,
                        h1Tag: newData.h1Tag,
                        metaTitle: newData.metaTitle,
                        metaDescription: newData.metaDescription,
                        active: newData.active,
                        productCount: newData.productCount
                    };
                }

                state.insertUpdateDepartment.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateDepartment.apiMsg.message = action.payload.message.message;
                toast.success(action.payload.message.message, {
                    position: "bottom-center",
                    toastId: "success"
                });
            }
        );
        builder.addCase(
            insertUpdateDepartmentsAction.pending,
            (state, action) => {
                state.insertUpdateDepartment.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateDepartmentsAction.rejected,
            (state, action) => {

                state.insertUpdateDepartment.apiMsg.message = action.error.message;
                state.insertUpdateDepartment.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: "bottom-center",
                    toastId: "error"
                });
            }
        );

        builder.addCase(changeStatusDepartmentsAction.fulfilled, (state, action) => {
            if (action.meta.arg.StatusType === Enums.ChangeStatus.Delete) {
                const existingRecords = state.allDepartments.data.records;
                const categoryIDToDelete = action.payload.data.Id;
                const updatedRecords = existingRecords.filter(record => record.categoryID !== categoryIDToDelete);
                state.allDepartments.data.records = updatedRecords;
                state.allDepartments.data.totalRecords = state.allDepartments.data.totalRecords - 1;
            }
            else if (action.meta.arg.StatusType === Enums.ChangeStatus.Active) {
                const newData = action.meta.arg;
                const existingRecords = state.allDepartments.data.records;

                const index = existingRecords.findIndex(record => record.categoryID === newData.Id);
                if (index !== -1) {
                    existingRecords[index] = {
                        ...existingRecords[index],
                        active: true,
                    };
                }
            }
            else if (action.meta.arg.StatusType === Enums.ChangeStatus.Inactive) {
                const newData = action.meta.arg;
                const existingRecords = state.allDepartments.data.records;

                const index = existingRecords.findIndex(record => record.categoryID === newData.Id);
                if (index !== -1) {
                    existingRecords[index] = {
                        ...existingRecords[index],
                        active: false,
                    };
                }

            }
            state.changeStatusDepartment.apiMsg.status = action.meta.requestStatus;
            state.changeStatusDepartment.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(changeStatusDepartmentsAction.pending, (state, action) => {
            state.changeStatusDepartment.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(changeStatusDepartmentsAction.rejected, (state, action) => {
            state.changeStatusDepartment.apiMsg.message = action.error.message;
            state.changeStatusDepartment.apiMsg.status = action.meta.requestStatus;
        });
    }
});

export const { RESET, ResetDepartmentDetailsState } = DepartmentReducer.actions;
export default DepartmentReducer.reducer;