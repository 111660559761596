import { combineReducers } from 'redux'
import authReducer from "./authReducer";
import RoleReducer from './settingReducers/RoleReducer';
import UserReducer from './settingReducers/UserReducer';
import BusinessReducer from './settingReducers/BusinessReducer';
import TaxReducer from './settingReducers/TaxReducer';
import DepartmentReducer from './productReducers/departmentReducers/departmentReducer';
import SubDepartmentReducer from './productReducers/departmentReducers/subDepartmentReducer';
import BrandReducer from "./productReducers/departmentReducers/brandReducer";
import UnitReducer from './productReducers/departmentReducers/UnitReducer';
import AnnouncementReducer from './webContentsSettingsReducers/AnnouncementReducer';
import HomeBannersReducers from './webContentsSettingsReducers/HomeBannersReducers';
import PromotionCategoryReducer from './promotionsReducers/promotionCategoryReducer';
import ProductReducer from './productReducers/productReducer/productReducer';
import PromotionReducer from "./promotionsReducers/promotionReducer";
import ZoneReducer from "./settingReducers/shippingZoneReducer"
import HolidayReducer from './settingReducers/holidayReducer';
import MenuReducer from './webContentsSettingsReducers/MenuReducer';
import OrdersReducer from "./ordersReducers/ordersReducer";
import CustomerReducer from "./customerReducers/customersReducer";
import dashboardReducers from './dashboardReducers/dashboardReducers';
import SalesSummaryReducer from './reportsReducers/salesSummaryReducer';
import productSalesSummaryReducer from './reportsReducers/productSalesSummaryReducer';
import productRefundSummaryReducer from './reportsReducers/productRefundSummaryReducer';
import DeliveryAndCollectionReducer from './reportsReducers/deliveryAndCollectionReducer';
import MissingProductReducer from './productReducers/missingProductReducers/missingProductReducer'
const reducer = {
  authReducer,
  RoleReducer,
  UserReducer,
  BusinessReducer,
  TaxReducer,
  DepartmentReducer,
  SubDepartmentReducer,
  BrandReducer,
  UnitReducer,
  AnnouncementReducer,
  HomeBannersReducers,
  PromotionCategoryReducer,
  ProductReducer,
  PromotionReducer,
  ZoneReducer,
  HolidayReducer,
  MenuReducer,
  OrdersReducer,
  CustomerReducer,
  dashboardReducers,
  SalesSummaryReducer,
  productSalesSummaryReducer,
  productRefundSummaryReducer,
  DeliveryAndCollectionReducer,
  MissingProductReducer
}

const appReducer = combineReducers(reducer);


const rootReducer = (state, action) => {
  //console.log("state",state);
  // console.log("action",action);
  if (action.type === 'auth/logout/fulfilled') {
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}


export default rootReducer;
// export default combineReducers(reducer);

export function CLEARSTORE() {
  return {
    type: "CLEARSTORE"
  };
}