import { createSlice } from "@reduxjs/toolkit";
import { getAllUnitAction, insertUpdateUnitAction, getUnitAction, UnitChangeStatusACTION } from "../../../actions/productsActions/departmentsActions/unitActions";
import { toast } from 'react-toastify';
import { Enums } from "../../../../helper/constants/enums";

const INITIAL_STATE = {
    Unit: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdateUnit: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    selectedUnit: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    deleteUnit: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    }
};

const UnitReducer = createSlice({
    name: "Unit",
    initialState: INITIAL_STATE,
    reducers: {

        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetUnitDetailsState(state, action) {
            state.selectedUnit = INITIAL_STATE.selectedUnit;
            state.insertUpdateUnit = INITIAL_STATE.insertUpdateUnit;
        },
    },

    extraReducers: (builder) => {

        builder.addCase(getAllUnitAction.fulfilled, (state, action) => {
            // console.log("action.payload.data", action.payload);
            state.Unit.data = action.payload.data;
            state.Unit.apiMsg.status = action.meta.requestStatus;
            state.Unit.apiMsg.message = "success";
            // console.log( "state.User.data",  state.User.data.records);
        });
        builder.addCase(getAllUnitAction.pending, (state, action) => {
            state.Unit.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllUnitAction.rejected, (state, action) => {
            state.Unit.apiMsg.message = action.error.message;
            state.Unit.apiMsg.status = action.meta.requestStatus;
        });


        
        builder.addCase(getUnitAction.fulfilled, (state, action) => {
            state.selectedUnit.data = action.payload.data;
            state.selectedUnit.apiMsg.status = action.meta.requestStatus;
            state.selectedUnit.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getUnitAction.pending, (state, action) => {
            state.selectedUnit.data = null;
            state.selectedUnit.apiMsg.message = action.meta.requestStatus;;
            state.selectedUnit.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getUnitAction.rejected, (state, action) => {
            state.selectedUnit.apiMsg.message = action.error.message;
            state.selectedUnit.apiMsg.status = action.meta.requestStatus;
        });


        builder.addCase(
            insertUpdateUnitAction.fulfilled,
            (state, action) => {

                // state.insertUpdateUnit.data = action.payload.data.data;
                
                // console.log("action.payload.data.data", action.payload);

                if (action.meta.arg) {
                    const newData = action.meta.arg;
                    const existingRecords = state.Unit.data.records;
                    const index = existingRecords ? existingRecords.findIndex(record => record.unitID === newData.unitID) : -1;

                    if (!newData.unitID) {
                        newData.unitID = action.payload.data.data;
                    }

                    if (index !== -1) {
                        existingRecords[index] = {
                            ...existingRecords[index],
                            ...newData
                        };
                    }
                    else {
                            state.Unit.data.records.push(newData);
                            state.Unit.data.totalRecords = state.Unit.data.totalRecords + 1;
                    }
                    state.Unit.data.records = existingRecords;
                    state.insertUpdateUnit.data = {
                        unitID: newData.unitID,
                        name: newData.name,
                    };
                }
                state.insertUpdateUnit.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateUnit.apiMsg.message = action.payload.message.message;
                toast.success(action.payload.message.message, {
                    position: "bottom-center",
                    toastId: "success"
                });
            }
        );
        builder.addCase(
            insertUpdateUnitAction.pending,
            (state, action) => {
                state.insertUpdateUnit.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateUnitAction.rejected,
            (state, action) => {

                state.insertUpdateUnit.apiMsg.message = action.error.message;
                state.insertUpdateUnit.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: "bottom-center",
                    toastId: "error"
                });
            }
        );




        builder.addCase(UnitChangeStatusACTION.fulfilled, (state, action) => {
            if (action.meta.arg.StatusType===Enums.ChangeStatus.Delete) {
                const existingRecords = state.Unit.data.records;
                const roleIdToDelete = action.payload.data.Id;
                const updatedRecords = existingRecords.filter(record => record.unitID !== roleIdToDelete);
                state.Unit.data.records = updatedRecords;
                state.Unit.data.totalRecords = state.Unit.data.totalRecords - 1;
            }
            else if (action.meta.arg.StatusType===Enums.ChangeStatus.Active){
                const newData = action.meta.arg;
                const existingRecords = state.Unit.data.records;

                const index = existingRecords.findIndex(record => record.unitID === newData.Id);
                if (index !== -1) {
                    existingRecords[index] = {
                        ...existingRecords[index],
                        active: true,
                    };
                }
            }
            else if ( action.meta.arg.StatusType===Enums.ChangeStatus.Inactive){
                const newData = action.meta.arg;
                const existingRecords = state.Unit.data.records;

                const index = existingRecords.findIndex(record => record.unitID === newData.Id);
                if (index !== -1) {
                    existingRecords[index] = {
                        ...existingRecords[index],
                        active: false,
                    };
                }

            }
            state.deleteUnit.apiMsg.status = action.meta.requestStatus;
            state.deleteUnit.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(UnitChangeStatusACTION.pending, (state, action) => {
            state.deleteUnit.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(UnitChangeStatusACTION.rejected, (state, action) => {
            state.deleteUnit.apiMsg.message = action.error.message;
            state.deleteUnit.apiMsg.status = action.meta.requestStatus;
        });
    }
})

export const { RESET, ResetUnitDetailsState} = UnitReducer.actions;
export default UnitReducer.reducer;