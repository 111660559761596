import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { Enums } from "../../../helper/constants/enums";
import { getAllSalesSummaryReportAction } from "../../actions/reportsAction/salesSummaryActions";

const INITIAL_STATE = {
    AllSalesSummaryData: {
        data: {},
        startDate : null,
        endDate : null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
}

const SalesSummaryReducer = createSlice({
    name: "SalesSummary",
    initialState: INITIAL_STATE,
    reducers: {

        ResetSalesSummaryState(state, action) {
            state = INITIAL_STATE;
        },

    },

    extraReducers: (builder) => {

        builder.addCase(getAllSalesSummaryReportAction.fulfilled, (state, action) => {
            // console.log(action.payload.data);
            // console.log(action.meta.arg.filter.deliveryOrCollectionDateFrom, action.meta.arg.filter.deliveryOrCollectionDateTo);
            
            state.AllSalesSummaryData.startDate = action.meta.arg && action.meta.arg.filter && action.meta.arg?.filter.deliveryOrCollectionDateFrom ? action.meta.arg?.filter.deliveryOrCollectionDateFrom : null;
            state.AllSalesSummaryData.endDate = action.meta.arg && action.meta.arg.filter &&  action.meta.arg?.filter.deliveryOrCollectionDateTo ? action.meta.arg?.filter.deliveryOrCollectionDateTo : null;
            state.AllSalesSummaryData.data = action.payload.data;
            state.AllSalesSummaryData.apiMsg.status = action.meta.requestStatus;
            state.AllSalesSummaryData.apiMsg.message = "success";
        });
        builder.addCase(getAllSalesSummaryReportAction.pending, (state, action) => {
            state.AllSalesSummaryData.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllSalesSummaryReportAction.rejected, (state, action) => {
            state.AllSalesSummaryData.apiMsg.message = action.error.message;
            state.AllSalesSummaryData.apiMsg.status = action.meta.requestStatus;
        });
    }
})

export const { ResetSalesSummaryState } = SalesSummaryReducer.actions;
export default SalesSummaryReducer.reducer;