import { useState } from "react";
import { getLocalStore,setLocalStore } from "../localStorage";
import { setCookie,getCookie } from "../cookieStorage";

export const useLocalStorage = (keyName, defaultValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = getLocalStore(keyName);
      if (value) {
        return value;
      } else {
        setLocalStore(keyName, defaultValue);
        return defaultValue;
      }
    } catch (err) {
      return defaultValue;
    }
  });
  const setValue = (newValue) => {
    try {
      setLocalStore(keyName, newValue);
    } catch (err) {
      console.log(err);
    }
    setStoredValue(newValue);
  };

  return [storedValue, setValue];
};

export const useCookieStorage = (keyName, defaultValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = getCookie(keyName);
      if (value) {
        return value;
      } else {
        if(defaultValue){
          setCookie(keyName, defaultValue);
        }
       
        return defaultValue;
      }
    } catch (err) {
      return defaultValue;
    }
  });
  const setValue = (newValue) => {
  
    try {
      if(newValue){
        setCookie(keyName, newValue.token,newValue.expires);
      }
      
    } catch (err) {
      console.log(err);
    }
    setStoredValue(newValue);
  };

  return [storedValue, setValue];
};

