import { InventoryMenus, PromotionsMenus, SuppliersMenu, ShopSettingsMenu, PermissionNames, MainMenus } from "./menus"
import { getLocalStore } from "../../localStorage/index";

const userInfo = getLocalStore(process.env.REACT_APP_LOCAL_STORAGE_KEY_USER);

export const DateFormatConstant = {
    APIDateFormat: 'YYYY-MM-DDTHH:mm:ss.SSSS[Z]',
    APITimeFormat: 'HH:mm',
    DisplayDateFormat: 'DD/MM/YYYY',
    DisplayDateFormatWithHourAndMinAndSec: 'DD/MM/YYYY HH:mm:ss',
    DisplayDateFormatWithHourAndMinAndSecAMPM: 'DD/MM/YYYY HH:mm:ss a',

    APIDateTimeFormat:'YYYY-MM-DDT00:00:00',
    // HolidayDateTimeFormat:'YYYY-MM-DDT00:00:00.417Z'
}
export const LabelSettings = {
    vatLabel: userInfo && userInfo.user && userInfo.user.vatLabel ? userInfo.user.vatLabel : "VAT"
};

export const ValidationExpression = {
    Email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
    URL: /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/,
    Password:/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])[A-Za-z\d@$!%*#?&]{10,}$/,
    Slug: /^[a-zA-Z0-9-]+$/
};


