import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../../API";
import { toast } from "react-toastify";



export const getAllRoleAction = createAsyncThunk(
    "Role/GetAllRole",
    async (data) => {
        if (data.dataGridDetails) {
            return {
                data: data.dataGridDetails.girdData
            }
        }
        else {
            delete data.dataGridDetails;
            return apiCall("/role/getall", "POST", data, false)
                .then((response) => {
                    if (response.status === 200) {
                        if (!response.data.hasError) {

                            return {
                                ...response.data,
                            }
                        } else {
                            // console.log("response error", response.data.message);
                            return Promise.reject({
                                ...response.data.message,
                            });
                        }
                    }
                })
                .catch((error) => {
                    return Promise.reject({
                        ...error,
                    });
                });
        }
    }
);

export const getRoleAction = createAsyncThunk(
    "Role/Getrole",
    async (data) => {
        return apiCall("/role/get", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        //console.log('response.data ',response.data)
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const insertUpdateRoleAction = createAsyncThunk(
    "Role/insertupdateRole",
    async (data) => {

        return apiCall("/role/insertupdate", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        let message = response.data.message
                        // console.log("message",message);
                        return {
                            data: {
                                ...response.data,
                            },
                            message: {
                                code: response.data.message.code,
                                type: response.data.message.type,
                                message: response.data.message.message
                            },
                            newRecord: data.id ? false : true
                        }
                    } else {
                        if (!response.data.message && response.data.errors && response.data.errors.length) {
                            response.data.message = response.data.errors[0];
                        }
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
                else {
                    return Promise.reject({
                        ...response.data,
                    });
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const RoleDeleteACTION = createAsyncThunk(
    "Role/delete",
    async (data) => {
        //console.logog("data2 ", data);
        return apiCall("/role/changestatus", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        toast.success(response.data.message.message, {
                            position: "bottom-center",
                        });
                        return {
                            ...response.data,
                            data: {
                                ...data,
                            },
                        };
                    } else {
                        toast.error(response.data.message.message, {
                            position: "bottom-center",
                        });
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: "bottom-center",
                });
                return Promise.reject({
                    ...error,
                });
            });
    }
);
