import { createContext, useContext, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage, useCookieStorage } from "./useLocalStorage";
import { MenuRoutes } from "../helper/constants/general-const";
import { Menus } from "../helper/constants/enums";
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [tokenDetails, setTokenDetails] = useCookieStorage(process.env.REACT_APP_TOKEN_AUTHENTICATION_KEY, null);
    const [user, setUser] = useLocalStorage(process.env.REACT_APP_LOCAL_STORAGE_KEY_USER, null);
    const navigate = useNavigate();

    useEffect(() => {
        if (!tokenDetails) {
            logout();
        }
    }, [tokenDetails])

    // call this function when you want to authenticate the user
    const login = async (data) => {
        setUser(data.user);
        setTokenDetails(data.tokenDetails);
        setTimeout(() => {
            handleNavigation(data);
        }, 100);
    };

    const handleNavigation = (data) => {
        let firstViewablePage = null;
        if(data.user.permissions.find(permission => permission.pageName == Menus.dashboard && permission.isView))
        {
            firstViewablePage = data.user.permissions.find(permission => permission.pageName == Menus.dashboard && permission.isView);
        }
        else{
            firstViewablePage = data.user.permissions.find(permission => permission.isView);
        }
        

        if (firstViewablePage) {
            const routeKey = Object.keys(MenuRoutes).find(key => MenuRoutes[key] === firstViewablePage.pageName);

            if (routeKey) {
                navigate(`/admin/${routeKey}`);

            } else {
                console.log('No matching route found for page:', firstViewablePage.pageName);
            }
        } else {
            logout();
            navigate(`/login`);
            console.log('No pages available with view permission.');
        }
    };




    // call this function to sign out logged in user
    const logout = () => {
        setUser(null);
        navigate("/", { replace: true });
    };

    const value = useMemo(
        () => ({
            user,
            tokenDetails,
            login,
            logout,
        }),
        [user]
    );
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};