import { createSlice } from "@reduxjs/toolkit";
import { getAllTaxAction, insertUpdateTaxAction, getTaxAction, TaxChangeStatusACTION } from "../../actions/settingActions/taxActions";
import { toast } from 'react-toastify';
import { Enums } from "../../../helper/constants/enums";

const INITIAL_STATE = {
    Tax: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdateTax: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    selectedTax: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    deleteTax: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    }
};

const TaxReducer = createSlice({
    name: "Tax",
    initialState: INITIAL_STATE,
    reducers: {

        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetTaxDetailsState(state, action) {
            state.selectedTax = INITIAL_STATE.selectedTax;
            state.insertUpdateTax = INITIAL_STATE.insertUpdateTax;
        }
    },

    extraReducers: (builder) => {

        builder.addCase(getAllTaxAction.fulfilled, (state, action) => {
            // console.log("action.payload.data", action.payload);
            state.Tax.data = action.payload.data;
            state.Tax.apiMsg.status = action.meta.requestStatus;
            state.Tax.apiMsg.message = "success";
            // console.log( "state.User.data",  state.User.data.records);
        });
        builder.addCase(getAllTaxAction.pending, (state, action) => {
            state.Tax.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllTaxAction.rejected, (state, action) => {
            state.Tax.apiMsg.message = action.error.message;
            state.Tax.apiMsg.status = action.meta.requestStatus;
        });



        builder.addCase(getTaxAction.fulfilled, (state, action) => {
            state.selectedTax.data = action.payload.data;
            state.selectedTax.apiMsg.status = action.meta.requestStatus;
            state.selectedTax.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getTaxAction.pending, (state, action) => {
            state.selectedTax.data = null;
            state.selectedTax.apiMsg.message = action.meta.requestStatus;;
            state.selectedTax.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getTaxAction.rejected, (state, action) => {
            state.selectedTax.apiMsg.message = action.error.message;
            state.selectedTax.apiMsg.status = action.meta.requestStatus;
        });


        builder.addCase(
            insertUpdateTaxAction.fulfilled,
            (state, action) => {
                if (action.meta.arg) {
                    const existingRecords = state.Tax.data.records;
                    const newData = action.meta.arg;
                    const index = existingRecords ? existingRecords.findIndex(record => record.taxID === newData.taxID) : -1;

                    if (!newData.taxID) {
                        newData.taxID = action.payload.data.data;
                    }
                    if (index !== -1) {
                        existingRecords[index] = {
                            ...existingRecords[index],
                            ...newData,
                        };
                    }
                    else {
                        existingRecords.push({
                            active: newData.active,
                            taxID: newData.taxID,
                            value: newData.value
                        });
                        state.Tax.data.totalRecords = state.Tax.data.totalRecords + 1;
                    }

                    state.Tax.data.records = existingRecords;


                    state.insertUpdateTax.data = {
                        taxID: newData.taxID,
                        value: newData.value,
                        active: newData.active
                    };
                }

                state.insertUpdateTax.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateTax.apiMsg.message = action.payload.message.message;
                toast.success(action.payload.message.message, {
                    position: "bottom-center",
                    toastId: "success"
                });
            }
        );
        builder.addCase(
            insertUpdateTaxAction.pending,
            (state, action) => {
                state.insertUpdateTax.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateTaxAction.rejected,
            (state, action) => {

                state.insertUpdateTax.apiMsg.message = action.error.message;
                state.insertUpdateTax.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: "bottom-center",
                    toastId: "error"
                });
            }
        );

        builder.addCase(TaxChangeStatusACTION.fulfilled, (state, action) => {
            if (action.meta.arg.StatusType === Enums.ChangeStatus.Delete) {
                const existingRecords = state.Tax.data.records;
                const roleIdToDelete = action.payload.data.Id;
                const updatedRecords = existingRecords.filter(record => record.taxID !== roleIdToDelete);
                state.Tax.data.records = updatedRecords;
                state.Tax.data.totalRecords = state.Tax.data.totalRecords - 1;
            }
            else {
                const newData = action.meta.arg;
                const existingRecords = state.Tax.data.records;
                // console.log("newData",newData)
                const index = existingRecords.findIndex(record => record.taxID === newData.taxID);
                if (index !== -1) {
                    existingRecords[index] = {
                        ...existingRecords[index],
                        active: newData.active,
                    };
                }
                state.Tax.data.records = existingRecords;
            }

            state.deleteTax.apiMsg.status = action.meta.requestStatus;
            state.deleteTax.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(TaxChangeStatusACTION.pending, (state, action) => {
            state.deleteTax.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(TaxChangeStatusACTION.rejected, (state, action) => {
            state.deleteTax.apiMsg.message = action.error.message;
            state.deleteTax.apiMsg.status = action.meta.requestStatus;
        });
    }
})

export const { RESET, ResetTaxDetailsState } = TaxReducer.actions;
export default TaxReducer.reducer;