import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { Enums } from "../../../helper/constants/enums";
import { getAllPromotionsAction, getPromotionAction, changeStatusPromotionAction, insertUpdatePromotionAction } from "../../actions/promotionsActions/promotionActions";
const INITIAL_STATE = {
    Promotions: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedPromotion: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdatePromotionDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    changeStatusPromotionDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    }
};

const PromotionReducer = createSlice({
    name: "Promotion",
    initialState: INITIAL_STATE,
    reducers: {

        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetPromotionDetailsState(state, action) {
            state.selectedPromotion = INITIAL_STATE.selectedPromotion;
            state.insertUpdatePromotionDetails = INITIAL_STATE.insertUpdatePromotionDetails;
            state.changeStatusPromotionDetails = INITIAL_STATE.changeStatusPromotionDetails;
        },
    },
    extraReducers: (builder) => {

        builder.addCase(getAllPromotionsAction.fulfilled, (state, action) => {
            state.Promotions.data = action.payload.data;
            state.Promotions.apiMsg.status = action.meta.requestStatus;
            state.Promotions.apiMsg.message = "success";
        });
        builder.addCase(getAllPromotionsAction.pending, (state, action) => {
            state.Promotions.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllPromotionsAction.rejected, (state, action) => {
            state.Promotions.apiMsg.message = action.error.message;
            state.Promotions.apiMsg.status = action.meta.requestStatus;
        });


        builder.addCase(getPromotionAction.fulfilled, (state, action) => {
            state.selectedPromotion.data = action.payload.data;
            state.selectedPromotion.apiMsg.status = action.meta.requestStatus;
            state.selectedPromotion.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getPromotionAction.pending, (state, action) => {
            state.selectedPromotion.data = null;
            state.selectedPromotion.apiMsg.message = action.meta.requestStatus;;
            state.selectedPromotion.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getPromotionAction.rejected, (state, action) => {
            state.selectedPromotion.apiMsg.message = action.error.message;
            state.selectedPromotion.apiMsg.status = action.meta.requestStatus;
        });


        builder.addCase(
            insertUpdatePromotionAction.fulfilled,
            (state, action) => {

                if (action.meta.arg) {
                    const newData = action.meta.arg;
                    const existingRecords = state.Promotions.data.records;

                    const index = existingRecords ? existingRecords.findIndex(record => record.promotionID === newData.promotionID) : -1;

                    if (!newData.promotionID) {
                        newData.promotionID = action.payload.data.data;
                    }

                    if (index !== -1) {
                        existingRecords[index] = {
                            ...existingRecords[index],
                            ...newData,

                        };
                    }
                    else {

                        existingRecords.push(newData);
                        state.Promotions.data.totalRecords = state.Promotions.data.totalRecords + 1;

                    }
                    state.Promotions.data.records = existingRecords;


                    state.insertUpdatePromotionDetails.data = {
                        ...newData
                    };
                }

                state.insertUpdatePromotionDetails.apiMsg.status = action.meta.requestStatus;
                state.insertUpdatePromotionDetails.apiMsg.message = action.payload.message.message;

                toast.success(action.payload.message.message, {
                    position: "bottom-center",
                    toastId: "success"
                });
            }
        );
        builder.addCase(
            insertUpdatePromotionAction.pending,
            (state, action) => {
                state.insertUpdatePromotionDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdatePromotionAction.rejected,
            (state, action) => {

                state.insertUpdatePromotionDetails.apiMsg.message = action.error.message;
                state.insertUpdatePromotionDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: "bottom-center",
                    toastId: "error"
                });
            }
        );


        builder.addCase(changeStatusPromotionAction.fulfilled, (state, action) => {
            if (action.meta.arg.StatusType === Enums.ChangeStatus.Delete) {
                const existingRecords = state.Promotions.data.records;
                const promotionIDToDelete = action.payload.data.Id;
                const updatedRecords = existingRecords.filter(record => record.promotionID !== promotionIDToDelete);
                state.Promotions.data.records = updatedRecords;
                state.Promotions.data.totalRecords = state.Promotions.data.totalRecords - 1;
            }
            else if (action.meta.arg.StatusType === Enums.ChangeStatus.Active) {
                const newData = action.meta.arg;
                const existingRecords = state.Promotions.data.records;

                const index = existingRecords.findIndex(record => record.promotionID === newData.Id);
                if (index !== -1) {
                    existingRecords[index] = {
                        ...existingRecords[index],
                        active: true,
                    };
                }
            }
            else if (action.meta.arg.StatusType === Enums.ChangeStatus.Inactive) {
                const newData = action.meta.arg;
                const existingRecords = state.Promotions.data.records;

                const index = existingRecords.findIndex(record => record.promotionID === newData.Id);
                if (index !== -1) {
                    existingRecords[index] = {
                        ...existingRecords[index],
                        active: false,
                    };
                }

            }
            state.changeStatusPromotionDetails.apiMsg.status = action.meta.requestStatus;
            state.changeStatusPromotionDetails.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(changeStatusPromotionAction.pending, (state, action) => {
            state.changeStatusPromotionDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(changeStatusPromotionAction.rejected, (state, action) => {
            state.changeStatusPromotionDetails.apiMsg.message = action.error.message;
            state.changeStatusPromotionDetails.apiMsg.status = action.meta.requestStatus;
        });
    }
});

export const { RESET, ResetPromotionDetailsState } = PromotionReducer.actions;
export default PromotionReducer.reducer;