import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../../API";
import { toast } from "react-toastify";


export const getAllProductSalesSummaryAction = createAsyncThunk(
    "Report/ProductSalesSummary",
    async (data) => {

        return apiCall("/admin/order/getallforproductsalesreport", "POST", data, false)
            .then((response) => {
                //   console.log("response",response)
                if (response.status === 200) {
                    if (!response.data.hasError) {

                        return {
                            ...response.data,
                        }
                    } else {
                        // console.log("response error", response.data.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }

);