import { createSlice } from "@reduxjs/toolkit";
import { getAllBusinessAction, getBusinessAction, insertUpdateBusinessAction, ChangeStatusBusinessAction, getBusinessInfoAction } from "../../actions/settingActions/businessAction";
import { toast } from 'react-toastify';

const INITIAL_STATE = {
    allBusiness: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    selectedBusiness: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    insertUpdateBusiness: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    changeStatusBusiness: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    businessInfo: {
        data: {
            records: [],
            // totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

};

const BusinessReducer = createSlice({
    name: "Business",
    initialState: INITIAL_STATE,
    reducers: {

        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetBusinessDetailsState(state, action) {
            state.selectedBusiness = INITIAL_STATE.selectedBusiness;
            state.insertUpdateBusiness = INITIAL_STATE.insertUpdateBusiness;
            state.changeStatusBusiness = INITIAL_STATE.changeStatusBusiness;
            state.businessInfo = INITIAL_STATE.businessInfo;
        },
    },
    extraReducers: (builder) => {

        builder.addCase(getAllBusinessAction.fulfilled, (state, action) => {
            // console.log("action.payload.data", action.payload);
            state.allBusiness.data = action.payload.data;
            state.allBusiness.apiMsg.status = action.meta.requestStatus;
            state.allBusiness.apiMsg.message = "success";
            // console.log( "state.allBusiness.data",  state.allBusiness.data.records);
        });
        builder.addCase(getAllBusinessAction.pending, (state, action) => {
            state.allBusiness.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllBusinessAction.rejected, (state, action) => {
            state.allBusiness.apiMsg.message = action.error.message;
            state.allBusiness.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(
            insertUpdateBusinessAction.fulfilled,
            (state, action) => {

                state.insertUpdateBusiness.data = action.payload.data.data;
                state.insertUpdateBusiness.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateBusiness.apiMsg.message = action.payload.message.message;


                toast.success(action.payload.message.message, {
                    position: "bottom-center",
                    toastId: "dept-error"
                });
            }
        );
        builder.addCase(
            insertUpdateBusinessAction.pending,
            (state, action) => {
                state.insertUpdateBusiness.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateBusinessAction.rejected,
            (state, action) => {

                state.insertUpdateBusiness.apiMsg.message = action.error.message;
                state.insertUpdateBusiness.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: "bottom-center",
                    toastId: "dept-error"
                });
            }
        );


        builder.addCase(getBusinessAction.fulfilled, (state, action) => {
            state.selectedBusiness.data = action.payload.data;
            state.selectedBusiness.apiMsg.status = action.meta.requestStatus;
            state.selectedBusiness.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getBusinessAction.pending, (state, action) => {
            state.selectedBusiness.data = null;
            state.selectedBusiness.apiMsg.message = action.meta.requestStatus;;
            state.selectedBusiness.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getBusinessAction.rejected, (state, action) => {
            state.selectedBusiness.apiMsg.message = action.error.message;
            state.selectedBusiness.apiMsg.status = action.meta.requestStatus;
        });


    
        builder.addCase(ChangeStatusBusinessAction.fulfilled, (state, action) => {
            if (action.meta.arg) {
                const existingRecords = state.allBusiness.data.records;
                const roleIdToDelete = action.payload.data.Id;

                const updatedRecords = existingRecords.filter(record => record.businessID !== roleIdToDelete);
                state.allBusiness.data.records = updatedRecords;
            }
            state.changeStatusBusiness.apiMsg.status = action.meta.requestStatus;
            state.changeStatusBusiness.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(ChangeStatusBusinessAction.pending, (state, action) => {
            state.changeStatusBusiness.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(ChangeStatusBusinessAction.rejected, (state, action) => {
            state.changeStatusBusiness.apiMsg.message = action.error.message;
            state.changeStatusBusiness.apiMsg.status = action.meta.requestStatus;
        });


        builder.addCase(getBusinessInfoAction.fulfilled, (state, action) => {
            // console.log("action.payload.data", action.payload);
            state.businessInfo.data = action.payload.data;
            state.businessInfo.apiMsg.status = action.meta.requestStatus;
            state.businessInfo.apiMsg.message = "success";
            // console.log( "state.businessInfo.data",  state.businessInfo.data.records);
        });
        builder.addCase(getBusinessInfoAction.pending, (state, action) => {
            state.businessInfo.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getBusinessInfoAction.rejected, (state, action) => {
            state.businessInfo.apiMsg.message = action.error.message;
            state.businessInfo.apiMsg.status = action.meta.requestStatus;
        });
    }
});

export const { RESET, ResetBusinessDetailsState } = BusinessReducer.actions;
export default BusinessReducer.reducer;