import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { Enums } from "../../../helper/constants/enums";
import { getAllDeliveryAndCollectionReportAction } from "../../actions/reportsAction/deliveryCollectionActions";

const INITIAL_STATE = {
    AllDeliveryAndCollectionData: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
}

const DeliveryAndCollectionReducer = createSlice({
    name: "DeliveryAndCollection",
    initialState: INITIAL_STATE,
    reducers: {

        ResetDeliveryAndCollectionState(state, action) {
            state = INITIAL_STATE;
        },

    },

    extraReducers: (builder) => {

        builder.addCase(getAllDeliveryAndCollectionReportAction.fulfilled, (state, action) => {
            // console.log(action.payload.data);

            state.AllDeliveryAndCollectionData.data = action.payload.data;
            state.AllDeliveryAndCollectionData.apiMsg.status = action.meta.requestStatus;
            state.AllDeliveryAndCollectionData.apiMsg.message = "success";
        });
        builder.addCase(getAllDeliveryAndCollectionReportAction.pending, (state, action) => {
            state.AllDeliveryAndCollectionData.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllDeliveryAndCollectionReportAction.rejected, (state, action) => {
            state.AllDeliveryAndCollectionData.apiMsg.message = action.error.message;
            state.AllDeliveryAndCollectionData.apiMsg.status = action.meta.requestStatus;
        });
    }
})

export const { ResetDeliveryAndCollectionState } = DeliveryAndCollectionReducer.actions;
export default DeliveryAndCollectionReducer.reducer;