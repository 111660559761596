
const ReducerStateContant = {
    fulfilled: 'fulfilled',
    pending: 'pending',
    rejected: 'rejected'
}

const MenuRoutes = {

    'dashboard': "Dashboard",
    'orders/orders': "Orders",
    'orders/deliveryandcollection': "DeliveryandCollection",
    'products/products': "Products",
    'products/departments/departments': "Departments",
    'products/departments/subdepartments': "SubDepartments",
    'products/departments/brands': "Brands",
    'products/departments/units': "Units",
    'promotions/promotions': "Promotions",
    'promotions/promotioncategory': "PromotionCategory",
    'customers': "Customers",
    'webContentsSettings': "webcontentssettings",
    'webcontentssettings/homebanners': "HomeBanners",
    'webcontentssettings/announcements': "Announcements",
    'webcontentssettings/mainmenusettings': "MainMenusettings",
    'reports/salessummary': "SalesSummary",
    'reports/productsalessummary': "ProductSalesSummary",
    'reports/productrefundsummary': "ProductRefundSummary",
    'settings/shippingzones/shippingzones': "ShippingZones",
    'settings/shippingzones/holiday': "Holiday",
    'settings/users': "users",
    'settings/tax': "Tax",
    'settings/business': "Business",
    'settings/users/usermanagement': "UserManagement",
    'settings/users/rolemanagement': "RoleManagement",
    'PromotionMainMenu': "Promotions",
    'home': "home",
    'reports': "Reports",
    'pickingReport': "pickingreport",
    'deliveryReport': "deliveryreport",
    'collectionReport': "collectionreport",
    'settings': "settings",

}

const defaultButtonColor = '#337ab7'
export { ReducerStateContant, defaultButtonColor, MenuRoutes };