import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { Enums } from "../../../helper/constants/enums";
import moment from "moment";
import { DateFormatConstant } from "../../../helper/constants/app-constant";
import { getAllOrdersAction, getOrderAction, changeStatuOrdersAction, insertUpdateOrderAction, getAllOrderStatusAction, getAllOrderStatusChangeHistoryAction, calculateRefundAction, fullRefundAction, partialRefundAction, bulkDownloadAction, bulkOrderDetailExcelAction, getAllBulkOrderStatusAction, bulkChangeStatuOrdersAction } from "../../actions/ordersActions/ordersAction";
const INITIAL_STATE = {
    Orders: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedOrder: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdateOrdersDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    changeStatusOrdersDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    bulkChangeStatusOrdersDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    OrderStatusData: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    BulkOrderStatusData: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    OrderStatusChangeDataHistory: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    calculateRefund: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    fullRefund: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    partialRefund: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    bulkDownload: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    bulkOrderDetailExcel: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
};

const OrdersReducer = createSlice({
    name: "Orders",
    initialState: INITIAL_STATE,
    reducers: {

        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetOrdersDetailsState(state, action) {
            state.selectedOrder = INITIAL_STATE.selectedOrder;
            state.bulkDownload = INITIAL_STATE.bulkDownload;
            state.insertUpdateOrdersDetails = INITIAL_STATE.insertUpdateOrdersDetails;
            state.changeStatusOrdersDetails = INITIAL_STATE.changeStatusOrdersDetails;
            state.bulkChangeStatusOrdersDetails = INITIAL_STATE.bulkChangeStatusOrdersDetails;
            state.bulkOrderDetailExcel = INITIAL_STATE.bulkOrderDetailExcel;
        },
        ResetOrderStatusChangeDataHistoryState(state, action) {
            state.OrderStatusChangeDataHistory = INITIAL_STATE.OrderStatusChangeDataHistory;
        },
        ResetCalculateRefund(state, action) {
            state.calculateRefund = INITIAL_STATE.calculateRefund;
        },
        ResetEditOrderState(state, action) {
            state.calculateRefund = INITIAL_STATE.calculateRefund;
            state.fullRefund = INITIAL_STATE.fullRefund;
            state.partialRefund = INITIAL_STATE.partialRefund;
        },
        ResetBulkChangeStatusOrdersDetails(state, action) {
            state.bulkChangeStatusOrdersDetails = INITIAL_STATE.bulkChangeStatusOrdersDetails;
        },
    },
    extraReducers: (builder) => {

        builder.addCase(getAllOrdersAction.fulfilled, (state, action) => {
            state.Orders.data = action.payload.data;
            state.Orders.apiMsg.status = action.meta.requestStatus;
            state.Orders.apiMsg.message = "success";
        });
        builder.addCase(getAllOrdersAction.pending, (state, action) => {
            state.Orders.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllOrdersAction.rejected, (state, action) => {
            state.Orders.apiMsg.message = action.error.message;
            state.Orders.apiMsg.status = action.meta.requestStatus;
        });


        builder.addCase(getOrderAction.fulfilled, (state, action) => {
            state.selectedOrder.data = action.payload.data;
            state.selectedOrder.apiMsg.status = action.meta.requestStatus;
            state.selectedOrder.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getOrderAction.pending, (state, action) => {
            state.selectedOrder.data = null;
            state.selectedOrder.apiMsg.message = action.meta.requestStatus;;
            state.selectedOrder.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getOrderAction.rejected, (state, action) => {
            state.selectedOrder.apiMsg.message = action.error.message;
            state.selectedOrder.apiMsg.status = action.meta.requestStatus;
        });


        builder.addCase(
            insertUpdateOrderAction.fulfilled,
            (state, action) => {

                if (action.meta.arg) {
                    const newData = action.meta.arg;
                    const existingRecords = state.Orders.data.records;

                    const index = existingRecords ? existingRecords.findIndex(record => record.orderID === newData.orderID) : -1;

                    if (!newData.orderID) {
                        newData.orderID = action.payload.data.data;
                    }

                    if (index !== -1) {
                        existingRecords[index] = {
                            ...existingRecords[index],
                            ...newData,

                        };
                    }
                    else {

                        existingRecords.push(newData);
                        state.Orders.data.totalRecords = state.Orders.data.totalRecords + 1;

                    }
                    state.Orders.data.records = existingRecords;


                    state.insertUpdateOrdersDetails.data = {
                        ...newData
                    };
                }

                state.insertUpdateOrdersDetails.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateOrdersDetails.apiMsg.message = action.payload.message.message;

                toast.success(action.payload.message.message, {
                    position: "bottom-center",
                    toastId: "success"
                });
            }
        );
        builder.addCase(
            insertUpdateOrderAction.pending,
            (state, action) => {
                state.insertUpdateOrdersDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateOrderAction.rejected,
            (state, action) => {

                state.insertUpdateOrdersDetails.apiMsg.message = action.error.message;
                state.insertUpdateOrdersDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: "bottom-center",
                    toastId: "error"
                });
            }
        );


        builder.addCase(changeStatuOrdersAction.fulfilled, (state, action) => {

            if (action.meta.arg.OrderStatus) {
                const newData = action.meta.arg;
                const existingRecords = state.Orders.data.records;
                const responseData = action.payload.data;
                // console.log(newData,'newData');
                // console.log(state.Orders.data.records, 'existing records');
                // console.log(action.payload.data, "data");


                // console.log(existingRecords.findIndex(record => record.orderID === newData.OrderID ));


                const index = existingRecords.findIndex(record => record.orderID === newData.OrderID);
                // console.log(index, "index");
                // console.log(Enums.OrderStatus.find((order) => order.value === parseInt(newData.OrderStatus)));
                // console.log(responseData.order.statusList);


                if (index !== -1) {
                    console.log("entered");

                    existingRecords[index] = {
                        ...existingRecords[index],
                        // ...newData,
                        orderStatus: responseData.order.orderStatus ? responseData.order.orderStatus : null,
                        orderStatusID: responseData.order.orderStatusID ? responseData.order.orderStatusID : null,

                        deliveryOrCollectionDate: responseData.order.deliveryOrCollectionDate ? moment(responseData.order.deliveryOrCollectionDate).format(DateFormatConstant.APIDateTimeFormat) : null,
                        statusList: responseData.order.statusList && responseData.order.statusList.length ? responseData.order.statusList : [],

                        orderType: responseData.order.orderType ? responseData.order.orderType : null,
                        paymentMode: responseData.order.paymentMode ? responseData.order.paymentMode : null,
                        transactionDate: responseData.order.transactionDate ? responseData.order.transactionDate : null,
                        totalAmount: responseData.order.totalAmount ? (responseData.order.totalAmount).toFixed(Enums.DecimalSettings.Default) : null,
                        invoiceNumber: responseData.order.invoiceNumber ? responseData.order.invoiceNumber : null,
                        mobileNo: responseData.order.mobileNo ? responseData.order.mobileNo : null,


                    };
                }
                state.Orders.data.records = existingRecords;
            }

            state.changeStatusOrdersDetails.apiMsg.status = action.meta.requestStatus;
            state.changeStatusOrdersDetails.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(changeStatuOrdersAction.pending, (state, action) => {
            state.changeStatusOrdersDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(changeStatuOrdersAction.rejected, (state, action) => {
            state.changeStatusOrdersDetails.apiMsg.message = action.error.message;
            state.changeStatusOrdersDetails.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(bulkChangeStatuOrdersAction.fulfilled, (state, action) => {


            const existingRecords = state.Orders.data.records;
            const bulkUpdateStatusresponseData = action.payload.data;
            if (bulkUpdateStatusresponseData && bulkUpdateStatusresponseData.length) {

                bulkUpdateStatusresponseData.map(function (res) {

                    let index = existingRecords.findIndex(record => record.orderID === res.orderID);

                    if (index !== -1) {
                        if (res.success) {
                            let responseData = res.orderData;
                            existingRecords[index] = {
                                ...existingRecords[index],
                                // ...newData,
                                orderStatus: responseData.order.orderStatus ? responseData.order.orderStatus : null,
                                orderStatusID: responseData.order.orderStatusID ? responseData.order.orderStatusID : null,

                                deliveryOrCollectionDate: responseData.order.deliveryOrCollectionDate ? moment(responseData.order.deliveryOrCollectionDate).format(DateFormatConstant.APIDateTimeFormat) : null,
                                statusList: responseData.order.statusList && responseData.order.statusList.length ? responseData.order.statusList : [],

                                orderType: responseData.order.orderType ? responseData.order.orderType : null,
                                paymentMode: responseData.order.paymentMode ? responseData.order.paymentMode : null,
                                transactionDate: responseData.order.transactionDate ? responseData.order.transactionDate : null,
                                totalAmount: responseData.order.totalAmount ? (responseData.order.totalAmount).toFixed(Enums.DecimalSettings.Default) : null,
                                invoiceNumber: responseData.order.invoiceNumber ? responseData.order.invoiceNumber : null,
                                mobileNo: responseData.order.mobileNo ? responseData.order.mobileNo : null,
                                updatedFailed: !res.success

                            };
                        }
                        else {
                            console.log("entered failed res.orderID", res.orderID);
                            existingRecords[index] = {
                                ...existingRecords[index],
                                updatedFailed: true

                            };
                        }

                    }
                })

                if (!bulkUpdateStatusresponseData.filter(x => x.success == false).length) {
                    toast.success(action.payload.message.message, {
                        position: "bottom-center",
                    });
                }
                state.Orders.data.records = existingRecords;

            }


            state.bulkChangeStatusOrdersDetails.data = action.payload.data;
            state.bulkChangeStatusOrdersDetails.apiMsg.status = action.meta.requestStatus;
            state.bulkChangeStatusOrdersDetails.apiMsg.message = action.payload.message.message;

        });
        builder.addCase(bulkChangeStatuOrdersAction.pending, (state, action) => {
            state.bulkChangeStatusOrdersDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(bulkChangeStatuOrdersAction.rejected, (state, action) => {
            state.bulkChangeStatusOrdersDetails.apiMsg.message = action.error.message;
            state.bulkChangeStatusOrdersDetails.apiMsg.status = action.meta.requestStatus;
        });


        builder.addCase(getAllOrderStatusAction.fulfilled, (state, action) => {
            state.OrderStatusData.data = action.payload.data;
            state.OrderStatusData.apiMsg.status = action.meta.requestStatus;
            state.OrderStatusData.apiMsg.message = "success";
        });
        builder.addCase(getAllOrderStatusAction.pending, (state, action) => {
            state.OrderStatusData.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllOrderStatusAction.rejected, (state, action) => {
            state.OrderStatusData.apiMsg.message = action.error.message;
            state.OrderStatusData.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getAllBulkOrderStatusAction.fulfilled, (state, action) => {
            state.BulkOrderStatusData.data = action.payload.data;
            state.BulkOrderStatusData.apiMsg.status = action.meta.requestStatus;
            state.BulkOrderStatusData.apiMsg.message = "success";
        });
        builder.addCase(getAllBulkOrderStatusAction.pending, (state, action) => {
            state.BulkOrderStatusData.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllBulkOrderStatusAction.rejected, (state, action) => {
            state.BulkOrderStatusData.apiMsg.message = action.error.message;
            state.BulkOrderStatusData.apiMsg.status = action.meta.requestStatus;
        });



        builder.addCase(getAllOrderStatusChangeHistoryAction.fulfilled, (state, action) => {
            state.OrderStatusChangeDataHistory.data = action.payload.data;
            state.OrderStatusChangeDataHistory.apiMsg.status = action.meta.requestStatus;
            state.OrderStatusChangeDataHistory.apiMsg.message = "success";
        });
        builder.addCase(getAllOrderStatusChangeHistoryAction.pending, (state, action) => {
            state.OrderStatusChangeDataHistory.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllOrderStatusChangeHistoryAction.rejected, (state, action) => {
            state.OrderStatusChangeDataHistory.apiMsg.message = action.error.message;
            state.OrderStatusChangeDataHistory.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(calculateRefundAction.fulfilled, (state, action) => {
            state.calculateRefund.data = action.payload.data;
            state.calculateRefund.apiMsg.status = action.meta.requestStatus;
            state.calculateRefund.apiMsg.message = "success";
        });
        builder.addCase(calculateRefundAction.pending, (state, action) => {
            state.calculateRefund.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(calculateRefundAction.rejected, (state, action) => {
            state.calculateRefund.apiMsg.message = action.error.message;
            state.calculateRefund.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(fullRefundAction.fulfilled, (state, action) => {

            const newData = action.meta.arg;
            const existingRecords = state.Orders.data.records;
            const responseData = action.payload.data;
            // console.log(newData,'newData');
            // console.log(state.Orders.data.records, 'existing records');
            // console.log(action.payload.data, "data");


            // console.log(existingRecords.findIndex(record => record.orderID === newData.OrderID ));


            const index = existingRecords.findIndex(record => record.orderID === newData.orderID);
            // console.log(index, "index");
            // console.log(Enums.OrderStatus.find((order) => order.value === parseInt(newData.OrderStatus)));
            // console.log(responseData.order.statusList);


            if (index !== -1) {
                console.log("entered");

                existingRecords[index] = {
                    ...existingRecords[index],
                    // ...newData,
                    orderStatus: responseData.order.orderStatus ? responseData.order.orderStatus : null,
                    orderStatusID: responseData.order.orderStatusID ? responseData.order.orderStatusID : null,

                    deliveryOrCollectionDate: responseData.order.deliveryOrCollectionDate ? moment(responseData.order.deliveryOrCollectionDate).format(DateFormatConstant.APIDateTimeFormat) : null,
                    statusList: responseData.order.statusList && responseData.order.statusList.length ? responseData.order.statusList : [],

                    orderType: responseData.order.orderType ? responseData.order.orderType : null,
                    paymentMode: responseData.order.paymentMode ? responseData.order.paymentMode : null,
                    transactionDate: responseData.order.transactionDate ? responseData.order.transactionDate : null,
                    totalAmount: responseData.order.totalAmount ? (responseData.order.totalAmount).toFixed(Enums.DecimalSettings.Default) : null,
                    invoiceNumber: responseData.order.invoiceNumber ? responseData.order.invoiceNumber : null,
                    mobileNo: responseData.order.mobileNo ? responseData.order.mobileNo : null,


                };
            }
            state.Orders.data.records = existingRecords;

            state.fullRefund.data = action.payload.data;
            state.fullRefund.apiMsg.status = action.meta.requestStatus;
            state.fullRefund.apiMsg.message = "success";

            toast.success(action.payload.message.message, {
                position: "bottom-center",
                toastId: "success"
            });
        });
        builder.addCase(fullRefundAction.pending, (state, action) => {
            state.fullRefund.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(fullRefundAction.rejected, (state, action) => {
            state.fullRefund.apiMsg.message = action.error.message;
            state.fullRefund.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(partialRefundAction.fulfilled, (state, action) => {
            const newData = action.meta.arg;
            const existingRecords = state.Orders.data.records;
            const responseData = action.payload.data;
            // console.log(newData,'newData');
            // console.log(state.Orders.data.records, 'existing records');
            // console.log(action.payload.data, "data");


            // console.log(existingRecords.findIndex(record => record.orderID === newData.OrderID ));


            const index = existingRecords.findIndex(record => record.orderID === newData.orderID);
            // console.log(index, "index");
            // console.log(Enums.OrderStatus.find((order) => order.value === parseInt(newData.OrderStatus)));
            // console.log(responseData.order.statusList);


            if (index !== -1) {
                console.log("entered");

                existingRecords[index] = {
                    ...existingRecords[index],
                    // ...newData,
                    orderStatus: responseData.order.orderStatus ? responseData.order.orderStatus : null,
                    orderStatusID: responseData.order.orderStatusID ? responseData.order.orderStatusID : null,
                    deliveryOrCollectionDate: responseData.order.deliveryOrCollectionDate ? moment(responseData.order.deliveryOrCollectionDate).format(DateFormatConstant.APIDateTimeFormat) : null,
                    statusList: responseData.order.statusList && responseData.order.statusList.length ? responseData.order.statusList : [],

                    orderType: responseData.order.orderType ? responseData.order.orderType : null,
                    paymentMode: responseData.order.paymentMode ? responseData.order.paymentMode : null,
                    transactionDate: responseData.order.transactionDate ? responseData.order.transactionDate : null,
                    totalAmount: responseData.order.totalAmount ? (responseData.order.totalAmount).toFixed(Enums.DecimalSettings.Default) : null,
                    invoiceNumber: responseData.order.invoiceNumber ? responseData.order.invoiceNumber : null,
                    mobileNo: responseData.order.mobileNo ? responseData.order.mobileNo : null,


                };
            }
            state.Orders.data.records = existingRecords;
            state.partialRefund.data = action.payload.data;
            state.partialRefund.apiMsg.status = action.meta.requestStatus;
            state.partialRefund.apiMsg.message = "success";

            toast.success(action.payload.message.message, {
                position: "bottom-center",
                toastId: "success"
            });
        });
        builder.addCase(partialRefundAction.pending, (state, action) => {
            state.partialRefund.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(partialRefundAction.rejected, (state, action) => {
            state.partialRefund.apiMsg.message = action.error.message;
            state.partialRefund.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(bulkDownloadAction.fulfilled, (state, action) => {
            if (action.meta.arg) {
                const newData = action.meta.arg;
                const existingRecords = state.Orders.data.records;

                console.log("existingRecords", existingRecords);
                console.log("newData action.meta.arg", newData);

                const orderIDsToExport = newData.orderDetail.map(detail => detail.orderID);

                const updatedRecords = existingRecords.map(record => {
                    if (orderIDsToExport.includes(record.orderID)) {
                        return {
                            ...record,
                            exported: !record.exported ? true : record.exported
                        };
                    }
                    return record;
                });

                state.Orders.data.records = updatedRecords;
            }

            state.bulkDownload.data = action.payload.data;
            state.bulkDownload.apiMsg.status = action.meta.requestStatus;
            state.bulkDownload.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(bulkDownloadAction.pending, (state, action) => {
            state.bulkDownload.data = null;
            state.bulkDownload.apiMsg.message = action.meta.requestStatus;;
            state.bulkDownload.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(bulkDownloadAction.rejected, (state, action) => {
            state.bulkDownload.apiMsg.message = action.error.message;
            state.bulkDownload.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(bulkOrderDetailExcelAction.fulfilled, (state, action) => {

            state.bulkOrderDetailExcel.data = action.payload.data;
            state.bulkOrderDetailExcel.apiMsg.status = action.meta.requestStatus;
            state.bulkOrderDetailExcel.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(bulkOrderDetailExcelAction.pending, (state, action) => {
            state.bulkOrderDetailExcel.data = null;
            state.bulkOrderDetailExcel.apiMsg.message = action.meta.requestStatus;;
            state.bulkOrderDetailExcel.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(bulkOrderDetailExcelAction.rejected, (state, action) => {
            state.bulkOrderDetailExcel.apiMsg.message = action.error.message;
            state.bulkOrderDetailExcel.apiMsg.status = action.meta.requestStatus;
        });

    }
});

export const { RESET, ResetOrdersDetailsState, ResetOrderStatusChangeDataHistoryState, ResetCalculateRefund, ResetEditOrderState, ResetBulkChangeStatusOrdersDetails } = OrdersReducer.actions;
export default OrdersReducer.reducer;