

import React, { Suspense } from 'react';
import { useAuth } from "./hooks/useAuth";

function App({ props }) {
  const { user, tokenDetails } = useAuth();
  return (

    <>
      {
        user && tokenDetails ?
          <Suspense fallback={<></>}>
            {React.createElement(React.lazy(() => import("./app-routes").catch((e) => { console.log(e) })), props)}
          </Suspense>
          :
          <Suspense fallback={<></>}>
            {React.createElement(React.lazy(() => import("./auth-routes").catch((e) => { console.log(e) })), props)}
          </Suspense>

      }
    </>
  );
}

export default App;
