import { createSlice } from "@reduxjs/toolkit";
import { getAllBrandsAction, insertUpdateBrandAction, getBrandAction, changeStatusBrandAction } from "../../../actions/productsActions/departmentsActions/brandActions";
import { toast } from 'react-toastify';
import { Enums } from "../../../../helper/constants/enums";

const INITIAL_STATE = {
    Brands: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedBrand: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdateBrandDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    changeStatusBrandDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    }
};

const BrandReducer = createSlice({
    name: "Brands",
    initialState: INITIAL_STATE,
    reducers: {

        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetBrandDetailsState(state, action) {
            state.selectedBrand = INITIAL_STATE.selectedBrand;
            state.insertUpdateBrandDetails = INITIAL_STATE.insertUpdateBrandDetails;
        },
    },
    extraReducers: (builder) => {

        builder.addCase(getAllBrandsAction.fulfilled, (state, action) => {
            state.Brands.data = action.payload.data;
            state.Brands.apiMsg.status = action.meta.requestStatus;
            state.Brands.apiMsg.message = "success";
        });
        builder.addCase(getAllBrandsAction.pending, (state, action) => {
            state.Brands.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllBrandsAction.rejected, (state, action) => {
            state.Brands.apiMsg.message = action.error.message;
            state.Brands.apiMsg.status = action.meta.requestStatus;
        });


        builder.addCase(getBrandAction.fulfilled, (state, action) => {
            state.selectedBrand.data = action.payload.data;
            state.selectedBrand.apiMsg.status = action.meta.requestStatus;
            state.selectedBrand.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getBrandAction.pending, (state, action) => {
            state.selectedBrand.data = null;
            state.selectedBrand.apiMsg.message = action.meta.requestStatus;;
            state.selectedBrand.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getBrandAction.rejected, (state, action) => {
            state.selectedBrand.apiMsg.message = action.error.message;
            state.selectedBrand.apiMsg.status = action.meta.requestStatus;
        });


        builder.addCase(
            insertUpdateBrandAction.fulfilled,
            (state, action) => {

                // state.insertUpdateBrandDetails.data = action.payload.data.data;

                // console.log("action.payload.data.data", action.payload);

                if (action.meta.arg) {
                    const newData = action.meta.arg;
                    const existingRecords = state.Brands.data.records;
                    const index = existingRecords ? existingRecords.findIndex(record => record.brandID === newData.brandID) : -1;

                    if (!newData.brandID) {
                        newData.brandID = action.payload.data.data;
                    }

                    if (index !== -1) {
                        existingRecords[index] = {
                            ...existingRecords[index],
                           ...newData
                        };
                    }
                    else {
                        existingRecords.push(newData);
                        state.Brands.data.totalRecords = state.Brands.data.totalRecords + 1;
                    }

                    state.Brands.data.records = existingRecords;

                    state.insertUpdateBrandDetails.data = {
                        brandID: newData.brandID,
                        name: newData.name,
                        image: newData.image,
                        active: newData.active
                    };
                }
                state.insertUpdateBrandDetails.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateBrandDetails.apiMsg.message = action.payload.message.message;
                toast.success(action.payload.message.message, {
                    position: "bottom-center",
                    toastId: "success"
                });
            }
        );
        builder.addCase(
            insertUpdateBrandAction.pending,
            (state, action) => {
                state.insertUpdateBrandDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateBrandAction.rejected,
            (state, action) => {

                state.insertUpdateBrandDetails.apiMsg.message = action.error.message;
                state.insertUpdateBrandDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: "bottom-center",
                    toastId: "error"
                });
            }
        );


        builder.addCase(changeStatusBrandAction.fulfilled, (state, action) => {
            if (action.meta.arg.StatusType === Enums.ChangeStatus.Delete) {
                const existingRecords = state.Brands.data.records;
                const brandIdToDelete = action.payload.data.Id;
                const updatedRecords = existingRecords.filter(record => record.brandID !== brandIdToDelete);
                state.Brands.data.records = updatedRecords;
                state.Brands.data.totalRecords = state.Brands.data.totalRecords - 1;
            }
            else if (action.meta.arg.StatusType === Enums.ChangeStatus.Active) {
                const newData = action.meta.arg;
                const existingRecords = state.Brands.data.records;

                const index = existingRecords.findIndex(record => record.brandID === newData.Id);
                if (index !== -1) {
                    existingRecords[index] = {
                        ...existingRecords[index],
                        active: true,
                    };
                }
            }
            else if (action.meta.arg.StatusType === Enums.ChangeStatus.Inactive) {
                const newData = action.meta.arg;
                const existingRecords = state.Brands.data.records;

                const index = existingRecords.findIndex(record => record.brandID === newData.Id);
                if (index !== -1) {
                    existingRecords[index] = {
                        ...existingRecords[index],
                        active: false,
                    };
                }

            }
            state.changeStatusBrandDetails.apiMsg.status = action.meta.requestStatus;
            state.changeStatusBrandDetails.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(changeStatusBrandAction.pending, (state, action) => {
            state.changeStatusBrandDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(changeStatusBrandAction.rejected, (state, action) => {
            state.changeStatusBrandDetails.apiMsg.message = action.error.message;
            state.changeStatusBrandDetails.apiMsg.status = action.meta.requestStatus;
        });
    }
});

export const { RESET, ResetBrandDetailsState } = BrandReducer.actions;
export default BrandReducer.reducer;