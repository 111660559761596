import { createSlice } from "@reduxjs/toolkit";
// import { getAllHomeBannersAction, getHomeBannersAction, insertUpdateHomeBannersAction, changeStatusHomeBannersAction } from "../../../actions/productsActions/departmentsActions/departmentsActions";
import { getAllHomeBannersAction, getHomeBannersAction, insertUpdateHomeBannersAction, changeStatusHomeBannersAction } from "../../actions/webContentsSettingsActions/homeBannersActions";
import { toast } from 'react-toastify';
import { Enums } from "../../../helper/constants/enums";

const INITIAL_STATE = {
    allHomeBanners: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedHomeBanner: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdateHomeBanner: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    changeStatusHomeBanner: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    }
};

const HomeBannersReducer = createSlice({
    name: "HomeBanner",
    initialState: INITIAL_STATE,
    reducers: {

        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetHomeBannerDetailsState(state, action) {
            state.selectedHomeBanner = INITIAL_STATE.selectedHomeBanner;
            state.insertUpdateHomeBanner = INITIAL_STATE.insertUpdateHomeBanner;
        },
    },
    extraReducers: (builder) => {

        builder.addCase(getAllHomeBannersAction.fulfilled, (state, action) => {
            // console.log("action.payload.data", action.payload);
            state.allHomeBanners.data = action.payload.data;
            state.allHomeBanners.apiMsg.status = action.meta.requestStatus;
            state.allHomeBanners.apiMsg.message = "success";
            // console.log( "state.allHomeBanners.data",  state.allHomeBanners.data.records);
        });
        builder.addCase(getAllHomeBannersAction.pending, (state, action) => {
            state.allHomeBanners.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllHomeBannersAction.rejected, (state, action) => {
            state.allHomeBanners.apiMsg.message = action.error.message;
            state.allHomeBanners.apiMsg.status = action.meta.requestStatus;
        });


        builder.addCase(getHomeBannersAction.fulfilled, (state, action) => {
            state.selectedHomeBanner.data = action.payload.data;
            state.selectedHomeBanner.apiMsg.status = action.meta.requestStatus;
            state.selectedHomeBanner.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getHomeBannersAction.pending, (state, action) => {
            state.selectedHomeBanner.data = null;
            state.selectedHomeBanner.apiMsg.message = action.meta.requestStatus;;
            state.selectedHomeBanner.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getHomeBannersAction.rejected, (state, action) => {
            state.selectedHomeBanner.apiMsg.message = action.error.message;
            state.selectedHomeBanner.apiMsg.status = action.meta.requestStatus;
        });


        builder.addCase(
            insertUpdateHomeBannersAction.fulfilled,
            (state, action) => {

                // state.insertUpdateHomeBanner.data = action.payload.data.data;

                if (action.meta.arg) {
                    const newData = action.meta.arg;
                    // console.log("dfadfdaf", action.meta.arg);
                    const existingRecords = state.allHomeBanners.data.records;
                    const index = existingRecords ? existingRecords.findIndex(record => record.advertisementID === newData.advertisementID) : -1;
                    if (!newData.advertisementID) {
                        newData.advertisementID = action.payload.data.data;
                    }
                    if (index !== -1) {
                        existingRecords[index] = {
                            ...existingRecords[index],
                            ...newData,
                        };
                    }
                    else {
                        state.allHomeBanners.data.records.push(newData);
                        state.allHomeBanners.data.totalRecords = state.allHomeBanners.data.totalRecords + 1;
                    }
                    state.allHomeBanners.data.records = existingRecords;
                    state.insertUpdateHomeBanner.data = {
                        advertisementID: action.payload.data.data,
                        itemID: newData.itemID,
                        url: newData.url,
                        externalLink: newData.externalLink,
                        image: newData.image,
                        sortOrder: newData.sortOrder,
                        itemType: newData.itemType,
                        itemTypeName: newData.itemTypeName,
                        active: newData.active
                    };
                }

                state.insertUpdateHomeBanner.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateHomeBanner.apiMsg.message = action.payload.message.message;

                toast.success(action.payload.message.message, {
                    position: "bottom-center",
                    toastId: "success"
                });
            }
        );
        builder.addCase(
            insertUpdateHomeBannersAction.pending,
            (state, action) => {
                state.insertUpdateHomeBanner.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateHomeBannersAction.rejected,
            (state, action) => {
                state.insertUpdateHomeBanner.apiMsg.message = action.error.message;
                state.insertUpdateHomeBanner.apiMsg.status = action.meta.requestStatus;
                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: "bottom-center",
                    toastId: "error"
                });
            }
        );


        builder.addCase(changeStatusHomeBannersAction.fulfilled, (state, action) => {
            console.log("action.meta.arg", action.meta.arg);
            if (action.meta.arg.StatusType === Enums.ChangeStatus.Delete) {
                const advertisementIDToDelete = action.meta.arg.Id;
                state.allHomeBanners.data.records = state.allHomeBanners.data.records.filter(record => record.advertisementID !== advertisementIDToDelete);
                state.allHomeBanners.data.totalRecords = state.allHomeBanners.data.totalRecords - 1;
                // console.log("action.payload.data", action);
            }
            else if (action.meta.arg.StatusType === Enums.ChangeStatus.Active) {
                const newData = action.meta.arg;
                // console.log("newData", newData);

                const existingRecords = state.allHomeBanners.data.records;
                // console.log("existingRecords", existingRecords);

                const index = existingRecords.findIndex(record => record.advertisementID === newData.Id);
                if (index !== -1) {
                    existingRecords[index] = {
                        ...existingRecords[index],
                        active: true,
                    };
                }
            }
            else if (action.meta.arg.StatusType === Enums.ChangeStatus.Inactive) {
                const newData = action.meta.arg;
                const existingRecords = state.allHomeBanners.data.records;

                const index = existingRecords.findIndex(record => record.categoryID === newData.Id);
                if (index !== -1) {
                    existingRecords[index] = {
                        ...existingRecords[index],
                        active: false,
                    };
                }

            }
            state.changeStatusHomeBanner.apiMsg.status = action.meta.requestStatus;
            state.changeStatusHomeBanner.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(changeStatusHomeBannersAction.pending, (state, action) => {
            state.changeStatusHomeBanner.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(changeStatusHomeBannersAction.rejected, (state, action) => {
            state.changeStatusHomeBanner.apiMsg.message = action.error.message;
            state.changeStatusHomeBanner.apiMsg.status = action.meta.requestStatus;
        });
    }
});

export const { RESET, ResetHomeBannerDetailsState } = HomeBannersReducer.actions;
export default HomeBannersReducer.reducer;