import { createSlice } from "@reduxjs/toolkit";
import { getAllZoneAction, getZoneAction, insertUpdateZoneAction, ZoneChangeStatusAction } from "../../actions/settingActions/shippingZoneActions";
import { toast } from 'react-toastify';
import { Enums } from "../../../helper/constants/enums";

const INITIAL_STATE = {
    Zones: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedZone: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdateZoneDetail: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    zoneChangeStatusDetail: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    }
};

const ZoneReducer = createSlice({
    name: "Zone",
    initialState: INITIAL_STATE,
    reducers: {

        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetZoneDetailsState(state, action) {
            state.selectedZone = INITIAL_STATE.selectedZone;
            state.insertUpdateZoneDetail = INITIAL_STATE.insertUpdateZoneDetail;
        },
    },
    extraReducers: (builder) => {

        builder.addCase(getAllZoneAction.fulfilled, (state, action) => {
            // console.log("action.payload.data", action.payload);
            state.Zones.data = action.payload.data;
            state.Zones.apiMsg.status = action.meta.requestStatus;
            state.Zones.apiMsg.message = "success";
            // console.log( "state.User.data",  state.User.data.records);
        });
        builder.addCase(getAllZoneAction.pending, (state, action) => {
            state.Zones.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllZoneAction.rejected, (state, action) => {
            state.Zones.apiMsg.message = action.error.message;
            state.Zones.apiMsg.status = action.meta.requestStatus;
        });


        builder.addCase(getZoneAction.fulfilled, (state, action) => {
            state.selectedZone.data = action.payload.data;
            state.selectedZone.apiMsg.status = action.meta.requestStatus;
            state.selectedZone.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getZoneAction.pending, (state, action) => {
            state.selectedZone.data = null;
            state.selectedZone.apiMsg.message = action.meta.requestStatus;;
            state.selectedZone.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getZoneAction.rejected, (state, action) => {
            state.selectedZone.apiMsg.message = action.error.message;
            state.selectedZone.apiMsg.status = action.meta.requestStatus;
        });


        builder.addCase(
            insertUpdateZoneAction.fulfilled,
            (state, action) => {
               
                if (action.meta.arg) {
                    const newData = action.meta.arg;
                    const existingRecords = state.Zones.data.records;
                    const index = existingRecords ? existingRecords.findIndex(record => record.zoneID === newData.zoneID) : -1;

                    if (!newData.zoneID) {
                        newData.zoneID = action.payload.data.data;
                    }
                    if (index !== -1) {
                        existingRecords[index] = {
                            ...existingRecords[index],
                            ...newData,
                        };
                    }
                    else {

                        existingRecords.push(newData);
                        state.Zones.data.totalRecords = state.Zones.data.totalRecords + 1;

                    }
                    state.Zones.data.records = existingRecords;

                    state.insertUpdateZoneDetail.data = {
                        ...newData
                    };
                }

                state.insertUpdateZoneDetail.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateZoneDetail.apiMsg.message = action.payload.message.message;
                toast.success(action.payload.message.message, {
                    position: "bottom-center",
                    toastId: "success"
                });
            }
        );
        builder.addCase(
            insertUpdateZoneAction.pending,
            (state, action) => {
                state.insertUpdateZoneDetail.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateZoneAction.rejected,
            (state, action) => {

                state.insertUpdateZoneDetail.apiMsg.message = action.error.message;
                state.insertUpdateZoneDetail.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: "bottom-center",
                    toastId: "error"
                });
            }
        );


        builder.addCase(ZoneChangeStatusAction.fulfilled, (state, action) => {
            if (action.meta.arg.StatusType === Enums.ChangeStatus.Delete) {
                const existingRecords = state.Zones.data.records;
                const roleIdToDelete = action.payload.data.Id;
                const updatedRecords = existingRecords.filter(record => record.zoneID !== roleIdToDelete);
                state.Zones.data.records = updatedRecords;
                state.Zones.data.totalRecords = state.Zones.data.totalRecords - 1;
            }
            else if (action.meta.arg.StatusType === Enums.ChangeStatus.Active) {
                const newData = action.meta.arg;
                const existingRecords = state.Zones.data.records;

                const index = existingRecords.findIndex(record => record.zoneID === newData.Id);
                if (index !== -1) {
                    existingRecords[index] = {
                        ...existingRecords[index],
                        active: true,
                    };
                }
            }
            else if (action.meta.arg.StatusType === Enums.ChangeStatus.Inactive) {
                const newData = action.meta.arg;
                const existingRecords = state.Zones.data.records;

                const index = existingRecords.findIndex(record => record.zoneID === newData.Id);
                if (index !== -1) {
                    existingRecords[index] = {
                        ...existingRecords[index],
                        active: false,
                    };
                }

            }
            state.zoneChangeStatusDetail.apiMsg.status = action.meta.requestStatus;
            state.zoneChangeStatusDetail.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(ZoneChangeStatusAction.pending, (state, action) => {
            state.zoneChangeStatusDetail.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(ZoneChangeStatusAction.rejected, (state, action) => {
            state.zoneChangeStatusDetail.apiMsg.message = action.error.message;
            state.zoneChangeStatusDetail.apiMsg.status = action.meta.requestStatus;
        });
    }
});

export const { RESET, ResetZoneDetailsState } = ZoneReducer.actions;
export default ZoneReducer.reducer;