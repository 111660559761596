import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../../API";
import { toast } from "react-toastify";

export const getAllDeliveryAndCollectionReportAction = createAsyncThunk(
    "DeliveryAndCollection/GetAllDeliveryAndCollectionReport",
    async (data) => {
        if (data.dataGridDetails) {
            return {
                data: data.dataGridDetails.gridData
            }
        }
        else {
            delete data.dataGridDetails;

            return apiCall("/admin/order/getallforprint", "POST", data, false)
                .then((response) => {
                    if (response.status === 200) {
                        if (!response.data.hasError) {

                            return {
                                ...response.data,
                            }
                        } else {
                            return Promise.reject({
                                ...response.data.message,
                            });
                        }
                    }
                })
                .catch((error) => {
                    return Promise.reject({
                        ...error,
                    });
                });
        }
    }
);