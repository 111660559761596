import { createSlice } from "@reduxjs/toolkit";
import { getAllRoleAction, getRoleAction, insertUpdateRoleAction, RoleDeleteACTION } from "../../actions/settingActions/roleActions";
import { toast } from 'react-toastify';

const INITIAL_STATE = {
    Role: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    selectedRole: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    insertUpdateRole: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    deleteRole: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    }

};

const RoleReducer = createSlice({
    name: "Role",
    initialState: INITIAL_STATE,
    reducers: {

        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetRoleDetailsState(state, action) {
            state.selectedRole = INITIAL_STATE.selectedRole;
            state.insertUpdateRole = INITIAL_STATE.insertUpdateRole;
            state.deleteRole = INITIAL_STATE.deleteRole;
        },
    },
    extraReducers: (builder) => {

        builder.addCase(getAllRoleAction.fulfilled, (state, action) => {
            // console.log("action.payload.data", action.payload);
            state.Role.data = action.payload.data;
            state.Role.apiMsg.status = action.meta.requestStatus;
            state.Role.apiMsg.message = "success";
            // console.log( "state.Role.data",  state.Role.data.records);
        });
        builder.addCase(getAllRoleAction.pending, (state, action) => {
            state.Role.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllRoleAction.rejected, (state, action) => {
            state.Role.apiMsg.message = action.error.message;
            state.Role.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(
            insertUpdateRoleAction.fulfilled,
            (state, action) => {

                // state.insertUpdateRole.data = action.payload.data.data;
    

                if (action.meta.arg) {
                    const newData = action.meta.arg;
                    const existingRecords = state.Role.data.records;

                    const index = existingRecords ? existingRecords.findIndex(record => record.roleID === newData.roleID) : -1;

                    if (!newData.roleID) {
                        newData.roleID = action.payload.data.data;
                    }

                    if (index !== -1) {
                        existingRecords[index] = {
                            ...existingRecords[index],
                            ...newData,
                        };
                    }
                    else {

                        // if (state.Role.data.records.length) {
                        //     state.Role.data.records.unshift({
                        //         roleID: action.payload.data.data,
                        //         roleName: newData.RoleName
                        //     });
                        // }
                        // else {
                            existingRecords.push(newData);
                            state.Role.data.totalRecords = state.Role.data.totalRecords + 1;
                        // }
                    }
                    state.Role.data.records = existingRecords;
                    state.insertUpdateRole.data = {
                        roleID: newData.roleID,
                        roleName: newData.roleName
                    };
                }
                state.insertUpdateRole.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateRole.apiMsg.message = action.payload.message.message;

                toast.success(action.payload.message.message, {
                    position: "bottom-center",
                    toastId: "success"
                });
            }
        );
        builder.addCase(
            insertUpdateRoleAction.pending,
            (state, action) => {
                state.insertUpdateRole.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateRoleAction.rejected,
            (state, action) => {

                state.insertUpdateRole.apiMsg.message = action.error.message;
                state.insertUpdateRole.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: "bottom-center",
                    toastId: "error"
                });
            }
        );


        builder.addCase(getRoleAction.fulfilled, (state, action) => {
            state.selectedRole.data = action.payload.data;
            state.selectedRole.apiMsg.status = action.meta.requestStatus;
            state.selectedRole.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getRoleAction.pending, (state, action) => {
            state.selectedRole.data = null;
            state.selectedRole.apiMsg.message = action.meta.requestStatus;;
            state.selectedRole.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getRoleAction.rejected, (state, action) => {
            state.selectedRole.apiMsg.message = action.error.message;
            state.selectedRole.apiMsg.status = action.meta.requestStatus;
        });


    
        builder.addCase(RoleDeleteACTION.fulfilled, (state, action) => {
            if (action.meta.arg) {
                const existingRecords = state.Role.data.records;
                const roleIdToDelete = action.payload.data.Id;

                const updatedRecords = existingRecords.filter(record => record.roleID !== roleIdToDelete);
                state.Role.data.records = updatedRecords;
                state.Role.data.totalRecords = state.Role.data.totalRecords - 1;
            }
            state.deleteRole.apiMsg.status = action.meta.requestStatus;
            state.deleteRole.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(RoleDeleteACTION.pending, (state, action) => {
            state.deleteRole.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(RoleDeleteACTION.rejected, (state, action) => {
            state.deleteRole.apiMsg.message = action.error.message;
            state.deleteRole.apiMsg.status = action.meta.requestStatus;
        });
    }
});

export const { RESET, ResetRoleDetailsState } = RoleReducer.actions;
export default RoleReducer.reducer;