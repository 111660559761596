import { createSlice } from "@reduxjs/toolkit";
import { getDashboardDetailsAction } from "../../actions/dashboardAction/dashboardActions";

const INITIAL_STATE = {
    Dashboard: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    }
};

const DashboardReducer = createSlice({
    name: "Dashboard",
    initialState: INITIAL_STATE,
    reducers: {

        RESET(state, action) {
            state = INITIAL_STATE;
        }
    },

    extraReducers: (builder) => {

        builder.addCase(getDashboardDetailsAction.fulfilled, (state, action) => {
            state.Dashboard.data = action.payload.data;
            state.Dashboard.apiMsg.status = action.meta.requestStatus;
            state.Dashboard.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getDashboardDetailsAction.pending, (state, action) => {
            state.Dashboard.data = null;
            state.Dashboard.apiMsg.message = action.meta.requestStatus;;
            state.Dashboard.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getDashboardDetailsAction.rejected, (state, action) => {
            state.Dashboard.apiMsg.message = action.error.message;
            state.Dashboard.apiMsg.status = action.meta.requestStatus;
        });

    }
});

export const { RESET } = DashboardReducer.actions;
export default DashboardReducer.reducer;