import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { Enums } from "../../../../helper/constants/enums";
import { getAllMissingProductsAction, getMissingProductAction, changeStatusMissingProductAction } from "../../../actions/productsActions/missingProductActions/missingProductActions";

const INITIAL_STATE = {
    MissingProducts: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedMissingProduct: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    changeStatusMissingProductDetail: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    }
};

const MissingProductReducer = createSlice({
    name: "MissingProduct",
    initialState: INITIAL_STATE,
    reducers: {

        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetMissingProductDetailsState(state, action) {
            state.selectedMissingProduct = INITIAL_STATE.selectedMissingProduct;
        },
    },

    extraReducers: (builder) => {

        builder.addCase(getAllMissingProductsAction.fulfilled, (state, action) => {
            state.MissingProducts.data = action.payload.data;
            state.MissingProducts.apiMsg.status = action.meta.requestStatus;
            state.MissingProducts.apiMsg.message = "success";
        });
        builder.addCase(getAllMissingProductsAction.pending, (state, action) => {
            state.MissingProducts.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllMissingProductsAction.rejected, (state, action) => {
            state.MissingProducts.apiMsg.message = action.error.message;
            state.MissingProducts.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getMissingProductAction.fulfilled, (state, action) => {
            state.selectedMissingProduct.data = action.payload.data;
            state.selectedMissingProduct.apiMsg.status = action.meta.requestStatus;
            state.selectedMissingProduct.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getMissingProductAction.pending, (state, action) => {
            state.selectedMissingProduct.data = null;
            state.selectedMissingProduct.apiMsg.message = action.meta.requestStatus;;
            state.selectedMissingProduct.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getMissingProductAction.rejected, (state, action) => {
            state.selectedMissingProduct.apiMsg.message = action.error.message;
            state.selectedMissingProduct.apiMsg.status = action.meta.requestStatus;
        });

        

        builder.addCase(changeStatusMissingProductAction.fulfilled, (state, action) => {
            console.log("state.MissingProducts.data",state.MissingProducts.data);
            
            if (action.meta.arg.StatusType === Enums.ChangeStatus.Delete) {
                const existingRecords = state.MissingProducts.data.records;
                const roleIdToDelete = action.payload.data.Id;
                const updatedRecords = existingRecords.filter(record => record.missingProductID !== roleIdToDelete);
                state.MissingProducts.data.records = updatedRecords;
                state.MissingProducts.data.totalRecords = state.MissingProducts.data.totalRecords - 1;
            }
            state.changeStatusMissingProductDetail.apiMsg.status = action.meta.requestStatus;
            state.changeStatusMissingProductDetail.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(changeStatusMissingProductAction.pending, (state, action) => {
            state.changeStatusMissingProductDetail.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(changeStatusMissingProductAction.rejected, (state, action) => {
            state.changeStatusMissingProductDetail.apiMsg.message = action.error.message;
            state.changeStatusMissingProductDetail.apiMsg.status = action.meta.requestStatus;
        });
    }
})

export const { RESET, ResetMissingProductDetailsState } = MissingProductReducer.actions;
export default MissingProductReducer.reducer;