import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../../API";
import { toast } from "react-toastify";

export const getAllOrdersAction = createAsyncThunk(
    "Orders/GetAllOrders",
    async (data) => {
        // console.log("data", data);
        // console.log("data.datagriddetails", data.dataGridDetails);
        if (data.dataGridDetails) {
            return {
                data: data.dataGridDetails.gridData
            }
        }
        else {
            delete data.dataGridDetails;

            return apiCall("/admin/order/getall", "POST", data, false)
                .then((response) => {
                    if (response.status === 200) {
                        if (!response.data.hasError) {

                            return {
                                ...response.data,
                            }
                        } else {
                            return Promise.reject({
                                ...response.data.message,
                            });
                        }
                    }
                })
                .catch((error) => {
                    return Promise.reject({
                        ...error,
                    });
                });
        }
    }
);

export const getOrderAction = createAsyncThunk(
    "Orders/GetOrder",
    async (data) => {
        return apiCall("/admin/order/get", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const insertUpdateOrderAction = createAsyncThunk(
    "Orders/insertUpdateOrder",
    async (data) => {

        return apiCall("/order/insertupdate", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        let message = response.data.message
                        return {
                            data: {
                                ...response.data,
                            },
                            message: {
                                code: response.data.message.code,
                                type: response.data.message.type,
                                message: response.data.message.message
                            },
                            newRecord: data.id ? false : true
                        }
                    } else {
                        if (!response.data.message && response.data.errors && response.data.errors.length) {
                            response.data.message = response.data.errors[0];
                        }
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
                else {
                    return Promise.reject({
                        ...response.data,
                    });
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const changeStatuOrdersAction = createAsyncThunk(
    "Orders/changeStatusOrders",
    async (data) => {
        return apiCall("/admin/order/changestatus", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        toast.success(response.data.message.message, {
                            position: "bottom-center",
                        });
                        return {
                            ...response.data,
                        };
                    } else {
                        toast.error(response.data.message.message, {
                            position: "bottom-center",
                        });
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: "bottom-center",
                });
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const bulkChangeStatuOrdersAction = createAsyncThunk(
    "Orders/BulkChangeStatusOrders",
    async (data) => {
        return apiCall("/admin/order/bulkchangestatus", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                       
                        return {
                            ...response.data,
                        };
                    } else {
                        toast.error(response.data.message.message, {
                            position: "bottom-center",
                        });
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: "bottom-center",
                });
                return Promise.reject({
                    ...error,
                });
            });
    }
);


export const getAllOrderStatusAction = createAsyncThunk(
    "Orders/GetAllOrderStatus",
    async (data) => {
        return apiCall("/admin/order/getallstatus", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {

                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);


export const getAllOrderStatusChangeHistoryAction = createAsyncThunk(
    "Orders/GetAllOrderStatusChangeHistory",
    async (data) => {
        return apiCall("/admin/order/getorderstatuschangehistory", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {

                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const getAllBulkOrderStatusAction = createAsyncThunk(
    "Orders/GetAllBulkOrderStatus",
    async (data) => {
        return apiCall("/admin/order/getallstatus", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {

                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const calculateRefundAction = createAsyncThunk(
    "Orders/CalculateRefund",
    async (data) => {
        return apiCall("/admin/order/calculaterefund", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);
export const fullRefundAction = createAsyncThunk(
    "Orders/FullRefund",
    async (data) => {
        return apiCall("/admin/order/changestatus", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);
export const partialRefundAction = createAsyncThunk(
    "Orders/PartialRefund",
    async (data) => {
        return apiCall("/admin/order/partialrefund", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const bulkDownloadAction = createAsyncThunk(
    "Orders/BulkDownload",
    async (data) => {
        return apiCall("/admin/order/bulkdownload", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const bulkOrderDetailExcelAction = createAsyncThunk(
    "Orders/BulkOrderDetailExcel",
    async (data) => {
        return apiCall("/admin/order/getbulkorderdetails", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);