import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { Enums } from "../../../helper/constants/enums";
// import { getAllProductSalesSummaryAction } from "../../actions/reportsAction/salesSummaryActions";
import { getAllProductSalesSummaryAction } from "../../actions/reportsAction/productSalesSummaryActions";

const INITIAL_STATE = {
    ProductSalesSummaryData: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
}

const ProductSalesSummaryReducer = createSlice({
    name: "ProductSalesSummary",
    initialState: INITIAL_STATE,
    reducers: {

        ResetProductSalesSummaryState(state, action) {
            state = INITIAL_STATE;
            // state.ProductSalesSummaryData = INITIAL_STATE.ProductSalesSummaryData;
        },

    },

    extraReducers: (builder) => {

        builder.addCase(getAllProductSalesSummaryAction.fulfilled, (state, action) => {
            // console.log(action.payload.data);
            state.ProductSalesSummaryData.data = action.payload.data;
            state.ProductSalesSummaryData.apiMsg.status = action.meta.requestStatus;
            state.ProductSalesSummaryData.apiMsg.message = "success";
        });
        builder.addCase(getAllProductSalesSummaryAction.pending, (state, action) => {
            state.ProductSalesSummaryData.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllProductSalesSummaryAction.rejected, (state, action) => {
            state.ProductSalesSummaryData.apiMsg.message = action.error.message;
            state.ProductSalesSummaryData.apiMsg.status = action.meta.requestStatus;
        });
    }
})

export const { ResetProductSalesSummaryState } = ProductSalesSummaryReducer.actions;
export default ProductSalesSummaryReducer.reducer;