import { createSlice } from "@reduxjs/toolkit";
import { getAllUserAction, getUserAction, insertUpdateUserAction, UserChangeStatusACTION } from "../../actions/settingActions/userActions";
import { toast } from 'react-toastify';
import { Enums } from "../../../helper/constants/enums";

const INITIAL_STATE = {
    User: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedUser: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdateUser: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    deleteUser: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    }
};

const UserReducer = createSlice({
    name: "User",
    initialState: INITIAL_STATE,
    reducers: {

        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetUserDetailsState(state, action) {
            state.selectedUser = INITIAL_STATE.selectedUser;
            state.insertUpdateUser = INITIAL_STATE.insertUpdateUser;
        },
    },
    extraReducers: (builder) => {

        builder.addCase(getAllUserAction.fulfilled, (state, action) => {
            // console.log("action.payload.data", action.payload);
            state.User.data = action.payload.data;
            state.User.apiMsg.status = action.meta.requestStatus;
            state.User.apiMsg.message = "success";
            // console.log( "state.User.data",  state.User.data.records);
        });
        builder.addCase(getAllUserAction.pending, (state, action) => {
            state.User.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllUserAction.rejected, (state, action) => {
            state.User.apiMsg.message = action.error.message;
            state.User.apiMsg.status = action.meta.requestStatus;
        });


        builder.addCase(getUserAction.fulfilled, (state, action) => {
            state.selectedUser.data = action.payload.data;
            state.selectedUser.apiMsg.status = action.meta.requestStatus;
            state.selectedUser.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getUserAction.pending, (state, action) => {
            state.selectedUser.data = null;
            state.selectedUser.apiMsg.message = action.meta.requestStatus;;
            state.selectedUser.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getUserAction.rejected, (state, action) => {
            state.selectedUser.apiMsg.message = action.error.message;
            state.selectedUser.apiMsg.status = action.meta.requestStatus;
        });


        builder.addCase(
            insertUpdateUserAction.fulfilled,
            (state, action) => {
                // state.insertUpdateUser.data = action.payload.data.data;
                if (action.meta.arg) {
                    const newData = action.meta.arg;
                    const existingRecords = state.User.data.records;
                    const index = existingRecords ? existingRecords.findIndex(record => record.userID === newData.userID) : -1;

                    if (!newData.userID) {
                        newData.userID = action.payload.data.data;
                    }
                    if (index !== -1) {
                        existingRecords[index] = {
                            ...existingRecords[index],
                             ...newData,
                        };
                    }
                    else {
                        // if (state.User.data.records.length) {
                        //     state.User.data.records.unshift({
                        //         UserID: action.payload.data.data,
                        //         firstName: newData.FirstName,
                        //         lastName: newData.LastName,
                        //         userName: newData.UserName,
                        //         roleID: newData.RoleID,
                        //         roleName: newData.RoleName,
                        //         active: newData.Active,
                        //     });
                        // }
                        // else {
                        existingRecords.push(newData);
                        state.User.data.totalRecords = state.User.data.totalRecords + 1;
                        // }
                    }
                    state.User.data.records = existingRecords;

                    state.insertUpdateUser.data = {
                        userID: newData.userID,
                        firstName: newData.firstName,
                        lastName: newData.lastName,
                        userName: newData.userName,
                        roleID: newData.roleID,
                        roleName: newData.roleName,
                        active: newData.active,
                    };
                }

                state.insertUpdateUser.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateUser.apiMsg.message = action.payload.message.message;
                toast.success(action.payload.message.message, {
                    position: "bottom-center",
                    toastId: "success"
                });
            }
        );
        builder.addCase(
            insertUpdateUserAction.pending,
            (state, action) => {
                state.insertUpdateUser.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateUserAction.rejected,
            (state, action) => {

                state.insertUpdateUser.apiMsg.message = action.error.message;
                state.insertUpdateUser.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: "bottom-center",
                    toastId: "error"
                });
            }
        );


        builder.addCase(UserChangeStatusACTION.fulfilled, (state, action) => {
            if (action.meta.arg.StatusType === Enums.ChangeStatus.Delete) {
                const existingRecords = state.User.data.records;
                const roleIdToDelete = action.payload.data.Id;
                const updatedRecords = existingRecords.filter(record => record.userID !== roleIdToDelete);
                state.User.data.records = updatedRecords;
                state.User.data.totalRecords = state.User.data.totalRecords - 1;
            }
            else if (action.meta.arg.StatusType === Enums.ChangeStatus.Active) {
                const newData = action.meta.arg;
                const existingRecords = state.User.data.records;

                const index = existingRecords.findIndex(record => record.userID === newData.Id);
                if (index !== -1) {
                    existingRecords[index] = {
                        ...existingRecords[index],
                        active: true,
                    };
                }
            }
            else if (action.meta.arg.StatusType === Enums.ChangeStatus.Inactive) {
                const newData = action.meta.arg;
                const existingRecords = state.User.data.records;

                const index = existingRecords.findIndex(record => record.userID === newData.Id);
                if (index !== -1) {
                    existingRecords[index] = {
                        ...existingRecords[index],
                        active: false,
                    };
                }

            }
            state.deleteUser.apiMsg.status = action.meta.requestStatus;
            state.deleteUser.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(UserChangeStatusACTION.pending, (state, action) => {
            state.deleteUser.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(UserChangeStatusACTION.rejected, (state, action) => {
            state.deleteUser.apiMsg.message = action.error.message;
            state.deleteUser.apiMsg.status = action.meta.requestStatus;
        });
    }
});

export const { RESET, ResetUserDetailsState } = UserReducer.actions;
export default UserReducer.reducer;