import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { Enums } from "../../../helper/constants/enums";
import { getAllCustomersAction, getCustomerAction, changeStatusCustomerAction, insertUpdateCustomerAction, getCustomerAddressAction } from "../../actions/customersAction/customersAction";

const INITIAL_STATE = {
    Customers: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedCustomer: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdateCustomerDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    changeStatusCustomerDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    customerAddressDetail: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    }
};

const CustomersReducer = createSlice({
    name: "Customers",
    initialState: INITIAL_STATE,
    reducers: {

        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetCustomersDetailsState(state, action) {
            state.selectedCustomer = INITIAL_STATE.selectedCustomer;
            state.insertUpdateCustomerDetails = INITIAL_STATE.insertUpdateCustomerDetails;
            // state.changeStatusCustomerDetails = INITIAL_STATE.changeStatusCustomerDetails;
        },
        ResetCustomersState(state, action) {
            state.Customers = INITIAL_STATE.Customers;
        },
        ResetCustomerAddressState(state, action) {
            state.customerAddressDetail = INITIAL_STATE.customerAddressDetail
        }
    },
    extraReducers: (builder) => {

        builder.addCase(getAllCustomersAction.fulfilled, (state, action) => {
            state.Customers.data = action.payload.data;
            state.Customers.apiMsg.status = action.meta.requestStatus;
            state.Customers.apiMsg.message = "success";
        });
        builder.addCase(getAllCustomersAction.pending, (state, action) => {
            state.Customers.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllCustomersAction.rejected, (state, action) => {
            state.Customers.apiMsg.message = action.error.message;
            state.Customers.apiMsg.status = action.meta.requestStatus;
        });


        builder.addCase(getCustomerAction.fulfilled, (state, action) => {
            state.selectedCustomer.data = action.payload.data;
            state.selectedCustomer.apiMsg.status = action.meta.requestStatus;
            state.selectedCustomer.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getCustomerAction.pending, (state, action) => {
            state.selectedCustomer.data = null;
            state.selectedCustomer.apiMsg.message = action.meta.requestStatus;;
            state.selectedCustomer.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getCustomerAction.rejected, (state, action) => {
            state.selectedCustomer.apiMsg.message = action.error.message;
            state.selectedCustomer.apiMsg.status = action.meta.requestStatus;
        });


        builder.addCase(
            insertUpdateCustomerAction.fulfilled,
            (state, action) => {

                if (action.meta.arg) {
                    const newData = action.meta.arg;
                    const existingRecords = state.Customers.data.records;

                    const index = existingRecords ? existingRecords.findIndex(record => record.userID === newData.userID) : -1;

                    if (!newData.userID) {
                        newData.userID = action.payload.data.data;
                    }

                    if (index !== -1) {
                        existingRecords[index] = {
                            ...existingRecords[index],
                            ...newData,

                        };
                    }
                    else {

                        existingRecords.push(newData);
                        state.Customers.data.totalRecords = state.Customers.data.totalRecords + 1;

                    }
                    state.Customers.data.records = existingRecords;


                    state.insertUpdateCustomerDetails.data = {
                        ...newData
                    };
                }

                state.insertUpdateCustomerDetails.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateCustomerDetails.apiMsg.message = action.payload.message.message;

                toast.success(action.payload.message.message, {
                    position: "bottom-center",
                    toastId: "success"
                });
            }
        );
        builder.addCase(
            insertUpdateCustomerAction.pending,
            (state, action) => {
                state.insertUpdateCustomerDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateCustomerAction.rejected,
            (state, action) => {

                state.insertUpdateCustomerDetails.apiMsg.message = action.error.message;
                state.insertUpdateCustomerDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: "bottom-center",
                    toastId: "error"
                });
            }
        );


        builder.addCase(changeStatusCustomerAction.fulfilled, (state, action) => {
            const existingRecords = state.Customers.data.records;
            if (action.meta.arg.StatusType === Enums.ChangeStatus.Delete) {
                const existingRecords = state.Customers.data.records;

                const customerIDToDelete = action.payload.data.Id;
                const updatedRecords = existingRecords.filter(record => record.userID !== customerIDToDelete);
                state.Customers.data.records = updatedRecords;
                state.Customers.data.totalRecords = state.Customers.data.totalRecords - 1;
            }
            else if (action.meta.arg.StatusType === Enums.ChangeStatus.LockUser) {
                const existingRecords = state.Customers.data.records;

                const newData = action.meta.arg;
                const index = existingRecords.findIndex(record => record.userID === newData.Id);

                if (index !== -1) {
                    existingRecords[index] = {
                        ...existingRecords[index],
                        lockAccount: true,
                    };
                }
                state.Customers.data.records = existingRecords;

            }
            else if (action.meta.arg.StatusType === Enums.ChangeStatus.UnLockUser) {
                const newData = action.meta.arg;
                const existingRecords = state.Customers.data.records;

                const index = existingRecords.findIndex(record => record.userID === newData.Id);
                if (index !== -1) {
                    existingRecords[index] = {
                        ...existingRecords[index],
                        lockAccount: false,
                    };
                }
                state.Customers.data.records = existingRecords;

            }
            state.changeStatusCustomerDetails.apiMsg.status = action.meta.requestStatus;
            state.changeStatusCustomerDetails.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(changeStatusCustomerAction.pending, (state, action) => {
            state.changeStatusCustomerDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(changeStatusCustomerAction.rejected, (state, action) => {
            state.changeStatusCustomerDetails.apiMsg.message = action.error.message;
            state.changeStatusCustomerDetails.apiMsg.status = action.meta.requestStatus;
        });


        builder.addCase(getCustomerAddressAction.fulfilled, (state, action) => {
            state.customerAddressDetail.data = action.payload.data;
            state.customerAddressDetail.apiMsg.status = action.meta.requestStatus;
            state.customerAddressDetail.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getCustomerAddressAction.pending, (state, action) => {
            state.customerAddressDetail.data = null;
            state.customerAddressDetail.apiMsg.message = action.meta.requestStatus;;
            state.customerAddressDetail.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getCustomerAddressAction.rejected, (state, action) => {
            state.customerAddressDetail.apiMsg.message = action.error.message;
            state.customerAddressDetail.apiMsg.status = action.meta.requestStatus;
        });

    }
});

export const { RESET, ResetCustomersDetailsState, ResetCustomersState, ResetCustomerAddressState } = CustomersReducer.actions;
export default CustomersReducer.reducer;