import { createSlice } from "@reduxjs/toolkit";
import { getAllProductRefundSummaryAction } from "../../actions/reportsAction/productRefundSummaryActions";

const INITIAL_STATE = {
    ProductRefundSummaryData: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
}

const ProductRefundSummaryReducer = createSlice({
    name: "ProductRefundSummary",
    initialState: INITIAL_STATE,
    reducers: {

        ResetProductRefundSummaryState(state, action) {
            state = INITIAL_STATE;
            // state.ProductRefundSummaryData = INITIAL_STATE.ProductRefundSummaryData;
        },

    },

    extraReducers: (builder) => {

        builder.addCase(getAllProductRefundSummaryAction.fulfilled, (state, action) => {
            // console.log(action.payload.data);
            state.ProductRefundSummaryData.data = action.payload.data;
            state.ProductRefundSummaryData.apiMsg.status = action.meta.requestStatus;
            state.ProductRefundSummaryData.apiMsg.message = "success";
        });
        builder.addCase(getAllProductRefundSummaryAction.pending, (state, action) => {
            state.ProductRefundSummaryData.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllProductRefundSummaryAction.rejected, (state, action) => {
            state.ProductRefundSummaryData.apiMsg.message = action.error.message;
            state.ProductRefundSummaryData.apiMsg.status = action.meta.requestStatus;
        });
    }
})

export const { ResetProductRefundSummaryState } = ProductRefundSummaryReducer.actions;
export default ProductRefundSummaryReducer.reducer;