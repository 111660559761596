import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../../API";
import { toast } from "react-toastify";


  export const getAnnouncementAction = createAsyncThunk(
    "Announcement/GetAnnouncement",
    async (data) => {
        return apiCall("/announcement/get", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        //console.log('response.data ',response.data)
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const insertUpdateAnnouncementAction = createAsyncThunk(
    "Announcement/insertupdateAnnouncement",
    async (data) => {

        return apiCall("/announcement/insertupdate", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        let message = response.data.message
                        // console.log("message",message);
                        return {
                            data: {
                                ...response.data,
                            },
                            message: {
                                code: response.data.message.code,
                                type: response.data.message.type,
                                message: response.data.message.message
                            },
                            newRecord: data.id ? false : true
                        }
                    } else {
                        if (!response.data.message && response.data.errors && response.data.errors.length) {
                            response.data.message = response.data.errors[0];
                        }
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
                else {
                    return Promise.reject({
                        ...response.data,
                    });
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);
