import { Menus } from "./enums";

const MainMenus = {
    Dashboard: Menus.dashboard,
    Orders: Menus.Orders,
    Home: Menus.home,
    Products: Menus.Products,
    Promotions: Menus.PromotionMainMenu,
    Customers: Menus.Customers,
    WebContentsSettings: Menus.webContentsSettings,
    Settings: Menus.settings,
    Reports: Menus.Reports,
};

const OrdersMenus = {
    orders: {
        Name: "Orders",
        Order: 1,
        ParentId: MainMenus.Orders,
        MenuId: Menus.Orders,
        HasSubMenu: false,
    },
    deliveryCollection: {
        Name: "Delivery & Collection",
        Order: 2,
        ParentId: MainMenus.Orders,
        MenuId: Menus.DeliveryCollection,
        HasSubMenu: false,
    },
    // pickingReport: {
    //     Name: "Picking Report",
    //     Order: 2,
    //     ParentId: MainMenus.Orders,
    //     MenuId: Menus.pickingReport,
    //     HasSubMenu: false,
    // },
    // deliveryReport: {
    //     Name: "Delivery Report",
    //     Order: 3,
    //     ParentId: MainMenus.Orders,
    //     MenuId: Menus.deliveryReport,
    //     HasSubMenu: false,
    // },
    // collectionReport: {
    //     Name: "Collection Report",
    //     Order: 4,
    //     ParentId: MainMenus.Orders,
    //     MenuId: Menus.collectionReport,
    //     HasSubMenu: false,
    // },
};



const ProductsDepartmentsSubMenus = {
    departments: {
        Name: "Departments",
        Order: 1,
        ParentId: "Departments",
        MenuId: Menus.Departments,
        HasSubMenu: false,
    },
    subDepartments: {
        Name: "Sub-Departments",
        Order: 2,
        ParentId: "Departments",
        MenuId: Menus.SubDepartments,
        HasSubMenu: false,
    },
    brands: {
        Name: "Brands",
        Order: 3,
        ParentId: "Departments",
        MenuId: Menus.Brands,
        HasSubMenu: false,
    },
    units: {
        Name: "Units",
        Order: 4,
        ParentId: "Departments",
        MenuId: Menus.Units,
        HasSubMenu: false,
    },
};

const ProductsMenus = {
    products: {
        Name: "Products",
        Order: 1,
        ParentId: MainMenus.Products,
        MenuId: Menus.Products,
        HasSubMenu: false,
    },
    departments: {
        Name: "Departments",
        Order: 2,
        ParentId: MainMenus.Products,
        MenuId: Menus.Departments,
        HasSubMenu: true,
        SubMenu: [ProductsDepartmentsSubMenus.departments, ProductsDepartmentsSubMenus.subDepartments, ProductsDepartmentsSubMenus.brands, ProductsDepartmentsSubMenus.units],
    },
    missingProducts: {
        Name: "Missing Product",
        Order: 3,
        ParentId: MainMenus.Products,
        MenuId: Menus.MissingProduct,
        HasSubMenu: false,
    },
};

const WebContentSettingsMenus = {
    homeBanners: {
        Name: "Home Banners",
        Order: 1,
        ParentId: MainMenus.WebContentsSettings,
        MenuId: Menus.HomeBanners,
        HasSubMenu: false,
    },
    announcements: {
        Name: "Announcements",
        Order: 2,
        ParentId: MainMenus.WebContentsSettings,
        MenuId: Menus.Announcements,
        HasSubMenu: false,
    },
    mainMenuSettings: {
        Name: "Main Menu Settings",
        Order: 3,
        ParentId: MainMenus.WebContentsSettings,
        MenuId: Menus.MainMenusettings,
        HasSubMenu: false,
    },
};

const SettingsUsersSubMenus = {
    users: {
        Name: "Users",
        Order: 1,
        ParentId: "Users",
        MenuId: Menus.UserManagement,
        HasSubMenu: false,
    },
    groups: {
        Name: "Groups",
        Order: 2,
        ParentId: "Users",
        MenuId: Menus.RoleManagement,
        HasSubMenu: false,
    },
};

const SettingsShippingZonesSubMenus = {
    shippingZones: {
        Name: "Shipping Zones",
        Order: 1,
        ParentId: "ShippingZones",
        MenuId: Menus.ShippingZones,
        HasSubMenu: false,
    },
    holiday: {
        Name: "Holiday",
        Order: 2,
        ParentId: "ShippingZones",
        MenuId: Menus.Holiday,
        HasSubMenu: false,
    },
};

const SettingsMenus = {
    shippingZones: {
        Name: "Shipping Zones",
        Order: 1,
        ParentId: MainMenus.Settings,
        MenuId: Menus.ShippingZones,
        HasSubMenu: true,
        SubMenu: [SettingsShippingZonesSubMenus.shippingZones, SettingsShippingZonesSubMenus.holiday],
    },
    tax: {
        Name: "TAX",
        Order: 2,
        ParentId: MainMenus.Settings,
        MenuId: Menus.Tax,
        HasSubMenu: false,
    },
    business: {
        Name: "Business",
        Order: 3,
        ParentId: MainMenus.Settings,
        MenuId: Menus.Business,
        HasSubMenu: false,
    },
    users: {
        Name: "Users",
        Order: 4,
        ParentId: MainMenus.Settings,
        MenuId: Menus.users,
        HasSubMenu: true,
        SubMenu: [SettingsUsersSubMenus.users, SettingsUsersSubMenus.groups],
    }
};

const PromotionsMenus = {
    promotions: {
        Name: "Promotions",
        Order: 1,
        ParentId: MainMenus.Promotions,
        MenuId: Menus.Promotions,
        HasSubMenu: false,
    },
    promotionCategory: {
        Name: "Promotion Category",
        Order: 2,
        ParentId: MainMenus.Promotions,
        MenuId: Menus.PromotionCategory,
        HasSubMenu: false,
    },
};

const ReportsMenus = {
    salesSummary: {
        Name: "Sales Summary",
        Order: 1,
        ParentId: MainMenus.Reports,
        MenuId: Menus.SalesSummary,
        HasSubMenu: false,
    },
    productSalesSummary: {
        Name: "Product Sales Summary",
        Order: 2,
        ParentId: MainMenus.Reports,
        MenuId: Menus.ProductSalesSummary,
        HasSubMenu: false,
    },
    productSalesRefund: {
        Name: "Product Refund Summary ",
        Order: 3,
        ParentId: MainMenus.Reports,
        MenuId: Menus.ProductRefundSummary,
        HasSubMenu: false,
    }
};

export const menuList = [
    {
        Name: "Dashboard",
        Order: 1,
        ParentId: null,
        MenuId: MainMenus.Dashboard,
        HasSubMenu: false,
        DisplayInSideMenu: true,
    },
    {
        Name: "Orders",
        Order: 2,
        ParentId: null,
        MenuId: MainMenus.Orders,
        HasSubMenu: true,
        DisplayInSideMenu: true,
        SubMenu: [OrdersMenus.orders, OrdersMenus.deliveryCollection],
    },
    {
        Name: "Products",
        Order: 3,
        ParentId: null,
        MenuId: MainMenus.Products,
        HasSubMenu: true,
        DisplayInSideMenu: true,
        SubMenu: [ProductsMenus.products, ProductsMenus.departments, ProductsMenus.missingProducts],
    },
    {
        Name: "Promotions",
        Order: 4,
        ParentId: null,
        MenuId: MainMenus.Promotions,
        HasSubMenu: true,
        DisplayInSideMenu: true,
        SubMenu: [PromotionsMenus.promotions, PromotionsMenus.promotionCategory],
    },
    {
        Name: "Customers",
        Order: 5,
        ParentId: null,
        MenuId: MainMenus.Customers,
        HasSubMenu: false,
        DisplayInSideMenu: true,
    },
    {
        Name: "Web Contents Settings",
        Order: 6,
        ParentId: null,
        MenuId: MainMenus.WebContentsSettings,
        HasSubMenu: true,
        DisplayInSideMenu: true,
        SubMenu: [WebContentSettingsMenus.homeBanners, WebContentSettingsMenus.announcements, WebContentSettingsMenus.mainMenuSettings],
    },
    {
        Name: "Reports",
        Order: 7,
        ParentId: null,
        MenuId: MainMenus.Reports,
        HasSubMenu: true,
        DisplayInSideMenu: true,
        SubMenu: [ReportsMenus.salesSummary, ReportsMenus.productSalesSummary, ReportsMenus.productSalesRefund],
    },
    {
        Name: "Settings",
        Order: 8,
        ParentId: null,
        MenuId: MainMenus.Settings,
        HasSubMenu: true,
        DisplayInSideMenu: true,
        SubMenu: [SettingsMenus.shippingZones, SettingsMenus.tax, SettingsMenus.business, SettingsMenus.users],
    },
];