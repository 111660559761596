import { createSlice } from "@reduxjs/toolkit";
import { getAllPromotionCategoryAction, insertUpdatePromotionCategoryAction, getPromotionCategoryAction, changeStatusPromotionCategoryAction } from "../../actions/promotionsActions/promotionCategoryActions";
import { toast } from 'react-toastify';
import { Enums } from "../../../helper/constants/enums";

const INITIAL_STATE = {
    PromotionCategory: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedPromotionCategory: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdatePromotionCategoryDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    changeStatusPromotionCategoryDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    }
};

const PromotionCategoryReducer = createSlice({
    name: "PromotionCategory",
    initialState: INITIAL_STATE,
    reducers: {

        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetPromotionCategoryDetailsState(state, action) {
            state.selectedPromotionCategory = INITIAL_STATE.selectedPromotionCategory;
            state.insertUpdatePromotionCategoryDetails= INITIAL_STATE.insertUpdatePromotionCategoryDetails;
            state.changeStatusPromotionCategoryDetails = INITIAL_STATE.changeStatusPromotionCategoryDetails;
            state.PromotionCategory = INITIAL_STATE.PromotionCategory;
        },
    },
    extraReducers: (builder) => {

        builder.addCase(getAllPromotionCategoryAction.fulfilled, (state, action) => {
            state.PromotionCategory.data = action.payload.data;
            state.PromotionCategory.apiMsg.status = action.meta.requestStatus;
            state.PromotionCategory.apiMsg.message = "success";
        });
        builder.addCase(getAllPromotionCategoryAction.pending, (state, action) => {
            state.PromotionCategory.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllPromotionCategoryAction.rejected, (state, action) => {
            state.PromotionCategory.apiMsg.message = action.error.message;
            state.PromotionCategory.apiMsg.status = action.meta.requestStatus;
        });


        builder.addCase(getPromotionCategoryAction.fulfilled, (state, action) => {
            state.selectedPromotionCategory.data = action.payload.data;
            state.selectedPromotionCategory.apiMsg.status = action.meta.requestStatus;
            state.selectedPromotionCategory.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getPromotionCategoryAction.pending, (state, action) => {
            state.selectedPromotionCategory.data = null;
            state.selectedPromotionCategory.apiMsg.message = action.meta.requestStatus;;
            state.selectedPromotionCategory.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getPromotionCategoryAction.rejected, (state, action) => {
            state.selectedPromotionCategory.apiMsg.message = action.error.message;
            state.selectedPromotionCategory.apiMsg.status = action.meta.requestStatus;
        });


        builder.addCase(
            insertUpdatePromotionCategoryAction.fulfilled,
            (state, action) => {

                // state.insertUpdatePromotionCategoryDetails.data = action.payload.data.data;

                if (action.meta.arg) {
                    const newData = action.meta.arg;
                    const existingRecords = state.PromotionCategory.data.records;

                    const index = existingRecords ? existingRecords.findIndex(record => record.promotionCategoryID === newData.promotionCategoryID) : -1;

                    if (!newData.promotionCategoryID) {
                        newData.promotionCategoryID = action.payload.data.data;
                    }

                    if (index !== -1) {
                        existingRecords[index] = {
                            ...existingRecords[index],
                            ...newData,
                            // name: newData.name,
                            // image: newData.image,
                            // sortOrder: newData.sortOrder,
                            // active: newData.active,
                        };
                    }
                    else {
                    //     if (state.PromotionCategory.data.records.length) {
                    //         state.PromotionCategory.data.records.unshift({
                    //             promotionCategoryID: action.payload.data.data,
                    //             name: newData.Name,
                    //             image: newData.Image,
                    //             sortOrder: newData.SortOrder,
                    //             active: newData.Active,
                    //         });
                    //     }
                    //     else {
                        existingRecords.push(newData);
                            state.PromotionCategory.data.totalRecords = state.PromotionCategory.data.totalRecords + 1;
                        // }
                    }
                    state.PromotionCategory.data.records = existingRecords;


                    state.insertUpdatePromotionCategoryDetails.data = {
                        promotionCategoryID: newData.promotionCategoryID,
                        name: newData.name,
                        image: newData.image,
                        sortOrder: newData.sortOrder,
                        active: newData.active,
                    };
                }

                state.insertUpdatePromotionCategoryDetails.apiMsg.status = action.meta.requestStatus;
                state.insertUpdatePromotionCategoryDetails.apiMsg.message = action.payload.message.message;

                toast.success(action.payload.message.message, {
                    position: "bottom-center",
                    toastId: "success"
                });
            }
        );
        builder.addCase(
            insertUpdatePromotionCategoryAction.pending,
            (state, action) => {
                state.insertUpdatePromotionCategoryDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdatePromotionCategoryAction.rejected,
            (state, action) => {

                state.insertUpdatePromotionCategoryDetails.apiMsg.message = action.error.message;
                state.insertUpdatePromotionCategoryDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: "bottom-center",
                    toastId: "error"
                });
            }
        );


        builder.addCase(changeStatusPromotionCategoryAction.fulfilled, (state, action) => {
            if (action.meta.arg.StatusType===Enums.ChangeStatus.Delete) {
                const existingRecords = state.PromotionCategory.data.records;
                const promotionCategoryIDToDelete = action.payload.data.Id;
                const updatedRecords = existingRecords.filter(record => record.promotionCategoryID !== promotionCategoryIDToDelete);
                state.PromotionCategory.data.records = updatedRecords;
                state.PromotionCategory.data.totalRecords = state.PromotionCategory.data.totalRecords - 1;
            }
            else if (action.meta.arg.StatusType===Enums.ChangeStatus.Active){
                const newData = action.meta.arg;
                const existingRecords = state.PromotionCategory.data.records;

                const index = existingRecords.findIndex(record => record.promotionCategoryID === newData.Id);
                if (index !== -1) {
                    existingRecords[index] = {
                        ...existingRecords[index],
                        active: true,
                    };
                }
            }
            else if ( action.meta.arg.StatusType===Enums.ChangeStatus.Inactive){
                const newData = action.meta.arg;
                const existingRecords = state.PromotionCategory.data.records;

                const index = existingRecords.findIndex(record => record.promotionCategoryID === newData.Id);
                if (index !== -1) {
                    existingRecords[index] = {
                        ...existingRecords[index],
                        active: false,
                    };
                }

            }
            state.changeStatusPromotionCategoryDetails.apiMsg.status = action.meta.requestStatus;
            state.changeStatusPromotionCategoryDetails.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(changeStatusPromotionCategoryAction.pending, (state, action) => {
            state.changeStatusPromotionCategoryDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(changeStatusPromotionCategoryAction.rejected, (state, action) => {
            state.changeStatusPromotionCategoryDetails.apiMsg.message = action.error.message;
            state.changeStatusPromotionCategoryDetails.apiMsg.status = action.meta.requestStatus;
        });
    }
});

export const { RESET, ResetPromotionCategoryDetailsState } = PromotionCategoryReducer.actions;
export default PromotionCategoryReducer.reducer;