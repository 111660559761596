import { createSlice } from "@reduxjs/toolkit";
import { getAllSubDepartmentsAction, getSubDepartmentAction, insertUpdateSubDepartmentsAction, changeStatusSubDepartmentsAction } from "../../../actions/productsActions/departmentsActions/subDepartmentsActions";
import { toast } from 'react-toastify';
import { Enums } from "../../../../helper/constants/enums";

const INITIAL_STATE = {
    allSubDepartments: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedSubDepartment: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdateSubDepartment: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    changeStatusSubDepartment: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    }
};

const SubDepartmentReducer = createSlice({
    name: "SubDepartment",
    initialState: INITIAL_STATE,
    reducers: {

        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetSubDepartmentDetailsState(state, action) {
            state.selectedSubDepartment = INITIAL_STATE.selectedSubDepartment;
            state.insertUpdateSubDepartment = INITIAL_STATE.insertUpdateSubDepartment;
        },
    },
    extraReducers: (builder) => {

        builder.addCase(getAllSubDepartmentsAction.fulfilled, (state, action) => {
            // console.log("action.payload.data", action.payload);
            state.allSubDepartments.data = action.payload.data;
            state.allSubDepartments.apiMsg.status = action.meta.requestStatus;
            state.allSubDepartments.apiMsg.message = "success";
            // console.log( "state.allSubDepartments.data",  state.allSubDepartments.data.records);
        });
        builder.addCase(getAllSubDepartmentsAction.pending, (state, action) => {
            state.allSubDepartments.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllSubDepartmentsAction.rejected, (state, action) => {
            state.allSubDepartments.apiMsg.message = action.error.message;
            state.allSubDepartments.apiMsg.status = action.meta.requestStatus;
        });


        builder.addCase(getSubDepartmentAction.fulfilled, (state, action) => {
            state.selectedSubDepartment.data = action.payload.data;
            state.selectedSubDepartment.apiMsg.status = action.meta.requestStatus;
            state.selectedSubDepartment.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getSubDepartmentAction.pending, (state, action) => {
            state.selectedSubDepartment.data = null;
            state.selectedSubDepartment.apiMsg.message = action.meta.requestStatus;
            state.selectedSubDepartment.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getSubDepartmentAction.rejected, (state, action) => {
            state.selectedSubDepartment.apiMsg.message = action.error.message;
            state.selectedSubDepartment.apiMsg.status = action.meta.requestStatus;
        });


        builder.addCase(
            insertUpdateSubDepartmentsAction.fulfilled,
            (state, action) => {
                // state.insertUpdateSubDepartment.data = action.payload.data.data;

                if (action.meta.arg) {
                    const newData = action.meta.arg;
                    const existingRecords = state.allSubDepartments.data.records;
                    const index = existingRecords ? existingRecords.findIndex(record => record.subCategoryID === newData.subCategoryID) : -1;

                    if (!newData.subCategoryID) {
                        newData.subCategoryID = action.payload.data.data;
                    }

                    if (index !== -1) {
                        existingRecords[index] = {
                            ...existingRecords[index],
                            ...newData
                        };
                    }
                    else {
                        existingRecords.push(newData);
                        state.allSubDepartments.data.totalRecords = state.allSubDepartments.data.totalRecords + 1;
                    }
                    state.allSubDepartments.data.records = existingRecords;

                    state.insertUpdateSubDepartment.data = {
                        subCategoryID: newData.subCategoryID,
                        categoryID: newData.categoryID,
                        name: newData.name,
                        description: newData.description,
                        image: newData.image,
                        h1Tag: newData.h1Tag,
                        metaTitle: newData.metaTitle,
                        metaDescription: newData.metaDescription,
                        active: newData.active,
                        productCount: newData.productCount,
                        categoryName: newData.categoryName
                    };
                }
                state.insertUpdateSubDepartment.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateSubDepartment.apiMsg.message = action.payload.message.message;
                toast.success(action.payload.message.message, {
                    position: "bottom-center",
                    toastId: "success"
                });
            }
        );
        builder.addCase(
            insertUpdateSubDepartmentsAction.pending,
            (state, action) => {
                state.insertUpdateSubDepartment.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateSubDepartmentsAction.rejected,
            (state, action) => {

                state.insertUpdateSubDepartment.apiMsg.message = action.error.message;
                state.insertUpdateSubDepartment.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: "bottom-center",
                    toastId: "error"
                });
            }
        );


        builder.addCase(changeStatusSubDepartmentsAction.fulfilled, (state, action) => {
            if (action.meta.arg.StatusType === Enums.ChangeStatus.Delete) {
                const existingRecords = state.allSubDepartments.data.records;
                const SubCategoryIDToDelete = action.payload.data.Id;
                const updatedRecords = existingRecords.filter(record => record.subCategoryID !== SubCategoryIDToDelete);
                state.allSubDepartments.data.records = updatedRecords;
                state.allSubDepartments.data.totalRecords = state.allSubDepartments.data.totalRecords - 1;
            }
            else if (action.meta.arg.StatusType === Enums.ChangeStatus.Active) {
                const newData = action.meta.arg;
                const existingRecords = state.allSubDepartments.data.records;

                const index = existingRecords.findIndex(record => record.subCategoryID === newData.Id);
                if (index !== -1) {
                    existingRecords[index] = {
                        ...existingRecords[index],
                        active: true,
                    };
                }
            }
            else if (action.meta.arg.StatusType === Enums.ChangeStatus.Inactive) {
                const newData = action.meta.arg;
                const existingRecords = state.allSubDepartments.data.records;

                const index = existingRecords.findIndex(record => record.subCategoryID === newData.Id);
                if (index !== -1) {
                    existingRecords[index] = {
                        ...existingRecords[index],
                        active: false,
                    };
                }

            }
            state.changeStatusSubDepartment.apiMsg.status = action.meta.requestStatus;
            state.changeStatusSubDepartment.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(changeStatusSubDepartmentsAction.pending, (state, action) => {
            state.changeStatusSubDepartment.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(changeStatusSubDepartmentsAction.rejected, (state, action) => {
            state.changeStatusSubDepartment.apiMsg.message = action.error.message;
            state.changeStatusSubDepartment.apiMsg.status = action.meta.requestStatus;
        });
    }
});

export const { RESET, ResetSubDepartmentDetailsState } = SubDepartmentReducer.actions;
export default SubDepartmentReducer.reducer;